import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import {
  createNewTarget,
  publish,
  changeRetypedPassword,
  updateGame,
  deleteGame,
  expandTargets,
  toggleShowAllTargets,
} from '../actions';
import BasicButton from '../components/BasicButton';
import BasicInput from '../components/BasicInput';
import Target from './Target';
import CheckBox from '../components/CheckBox';
import mapPropsToStyles from '../components/common/mapPropsToStyles';
import classes from './Tournament.module.css';

class Tournament extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.targetsRef = React.createRef();
    this.newCreated = false;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      !(
        this.props.invalidTournament.title ||
        this.props.invalidTournament.link ||
        this.props.invalidTournament.target ||
        this.props.invalidTournament.targetName ||
        this.props.invalidTournament.targetDeadline ||
        this.props.invalidTournament.targetFirst ||
        this.props.invalidTournament.targetSecond ||
        this.props.invalidTournament.targetBetResults ||
        this.props.invalidTournament.targetOptions ||
        this.props.invalidTournament.resultFirst ||
        this.props.invalidTournament.resultSecond ||
        this.props.invalidTournament.resultOptions ||
        this.props.invalidTournament.result1x2 ||
        this.props.invalidTournament.resultScore
      ) &&
      (newProps.invalidTournament.title ||
        newProps.invalidTournament.link ||
        newProps.invalidTournament.target ||
        newProps.invalidTournament.targetName ||
        newProps.invalidTournament.targetDeadline ||
        newProps.invalidTournament.targetFirst ||
        newProps.invalidTournament.targetSecond ||
        newProps.invalidTournament.targetBetResults ||
        newProps.invalidTournament.targetOptions ||
        newProps.invalidTournament.resultFirst ||
        newProps.invalidTournament.resultSecond ||
        newProps.invalidTournament.resultOptions ||
        newProps.invalidTournament.result1x2 ||
        newProps.invalidTournament.resultScore)
    ) {
      this.containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  componentDidUpdate() {
    if (this.newCreated) {
      this.newCreated = false;
      this.containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }

  onCreateNew() {
    this.newCreated = true;
    this.props.expandTargets('less', 0, true);
    this.props.createNewTarget();
  }

  onTryToPublish() {
    this.props.publish();
  }

  onGameChanged(type, value) {
    if (type === 'title') {
      this.props.game.name = value;
    } else if (type === 'link') {
      this.props.game.link = value;
    } else if (type === 'description') {
      this.props.game.description = value;
    } else if (type === 'password') {
      this.props.game.password = value;
    }
    this.props.updateGame(this.props.game);
  }

  onTinyTargetPressed(index, target) {
    if (target.open) {
      this.props.expandTargets('less', index, true);
    } else {
      this.targetsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      this.props.expandTargets('more', index, true);
    }
  }

  onShowAllTargetsPressed() {
    this.props.toggleShowAllTargets(!this.props.showAllTargets);
  }

  renderTargetCluster() {
    const cluster = this.props.game.targets.map((target, index) =>
      this.renderTinyTarget(target, index),
    );
    return <div className={classes.clusterContainer}>{cluster}</div>;
  }

  getFullTargetNameString(target) {
    let name = target.name.trim();
    const home = target.home ? target.home.trim() : false;
    const visitor = target.visitor ? target.visitor.trim() : false;
    if (target.home || target.visitor) {
      if (name && name.slice(-1) !== ':') {
        name += ': ';
      }
      let homeFound = false;
      if (home) {
        name += home;
        homeFound = true;
      }
      if (visitor) {
        if (homeFound) {
          name += ' - ';
        }
        name += visitor;
      }
    }
    return name
  }

  renderTinyTarget(target, index) {
    if (
      this.props.usageType !== 'new' &&
      !this.props.showAllTargets &&
      ((this.props.usageType === 'updateResults' && target.results) ||
        (this.props.usageType === 'updateResults' &&
          target.deadline &&
          target.deadline > Date.now()) ||
        (this.props.usageType !== 'updateResults' &&
          target.deadline &&
          target.deadline < Date.now()))
    ) {
      return null;
    }

    const name = this.getFullTargetNameString(target);

    return (
      <button
        type="button"
        key={index}
        className={mapPropsToStyles(
          [classes.clusterButton],
          {
            darkButton: target.open,
          },
          classes,
        )}
        onClick={this.onTinyTargetPressed.bind(this, index, target)}
      >
        {name}
      </button>
    );
  }

  renderTargets() {
    return (
      <div ref={this.targetsRef}>
        {this.props.game.targets.map((target, index) =>
          this.renderTarget(target, index),
        )}
      </div>
    );
  }

  renderTarget(target, index) {
    return (
      <Target
        key={index}
        index={index}
        content={target}
        targetCount={this.props.game.targets.length}
      />
    );
  }

  renderPublish() {
    if (this.props.unpublishedChanges) {
      const mobileMenubarVisible =
        this.props.usageType === 'modify' ||
        this.props.usageType === 'updateResults' ||
        this.props.usageType === 'shareContent' ||
        this.props.usageType === 'userControl';
      return (
        <BasicButton
          onClick={this.onTryToPublish.bind(this)}
          darkButton
          marginTopButton
          mobileSave
          mobileMenubarVisible={mobileMenubarVisible}
        >
          {this.props.game.id ? this.props.t('SAVE_CHANGES') : this.props.t('NEW_PUBLISH')}
        </BasicButton>
      );
    }
    return null;
  }

  renderAddTargetButton() {
    if (this.props.usageType !== 'updateResults') {
      return (
        <BasicButton onClick={this.onCreateNew.bind(this)} marginTopButton>
          {this.props.t('NEW_TARGET_BUTTON')}
        </BasicButton>
      );
    }
  }

  renderInvalidWarnings() {
    const warnings = [];
    if (
      this.props.participants &&
      this.props.participants.length > 0 &&
      this.props.usageType === 'modify'
    ) {
      warnings.push(
        <p key={1} className={classes.description}>
          {this.props.t('PARTICIPANTS_WARNING')}
        </p>,
      );
    }
    if (this.props.invalidTournament.name) {
      warnings.push(
        <p key={1} className={classes.description}>
          {this.props.t('INVALID_TITLE')}
        </p>,
      );
    }
    if (this.props.invalidTournament.link) {
      warnings.push(
        <p key={2} className={classes.description}>
          {this.props.t('INVALID_LINK')}
        </p>,
      );
    }
    if (this.props.invalidTournament.password) {
      warnings.push(
        <p key={13} className={classes.description}>
          {this.props.t('INVALID_PASSWORD')}
        </p>,
      );
    }
    if (this.props.invalidTournament.target) {
      warnings.push(
        <p key={3} className={classes.description}>
          {this.props.t('INVALID_TARGET')}
        </p>,
      );
    }
    if (this.props.invalidTournament.targetName) {
      warnings.push(
        <p key={4} className={classes.description}>
          {this.props.t('INVALID_TARGET_NAME')}
        </p>,
      );
    }
    if (this.props.invalidTournament.targetDeadline) {
      warnings.push(
        <p key={5} className={classes.description}>
          {this.props.t('INVALID_TARGET_DEADLINE')}
        </p>,
      );
    }
    if (
      this.props.invalidTournament.targetFirst ||
      this.props.invalidTournament.targetSecond
    ) {
      warnings.push(
        <p key={6} className={classes.description}>
          {this.props.t('INVALID_TARGET_LINKS')}
        </p>,
      );
    }
    if (this.props.invalidTournament.targetBetResults) {
      warnings.push(
        <p key={7} className={classes.description}>
          {this.props.t('INVALID_TARGET_BET_RESULTS')}
        </p>,
      );
    }
    if (this.props.invalidTournament.targetOptions) {
      warnings.push(
        <p key={8} className={classes.description}>
          {this.props.t('INVALID_TARGET_OPTIONS')}
        </p>,
      );
    }
    if (
      this.props.invalidTournament.resultFirst ||
      this.props.invalidTournament.resultSecond
    ) {
      warnings.push(
        <p key={9} className={classes.description}>
          {this.props.t('INVALID_RESULT_LINKS')}
        </p>,
      );
    }
    if (this.props.invalidTournament.resultOptions) {
      warnings.push(
        <p key={10} className={classes.description}>
          {this.props.t('INVALID_RESULT_OPTIONS')}
        </p>,
      );
    }
    if (this.props.invalidTournament.result1x2) {
      warnings.push(
        <p key={11} className={classes.description}>
          {this.props.t('INVALID_RESULT_1X2')}
        </p>,
      );
    }
    if (this.props.invalidTournament.result12) {
      warnings.push(
        <p key={11} className={classes.description}>
          {this.props.t('INVALID_RESULT_12')}
        </p>,
      );
    }
    if (this.props.invalidTournament.resultScore) {
      warnings.push(
        <p key={12} className={classes.description}>
          {this.props.t('INVALID_RESULT_SCORE')}
        </p>,
      );
    }

    if (warnings.length > 0) {
      return <div className={classes.warningsContainer}>{warnings}</div>;
    }
  }

  renderPassword() {
    if (this.props.usageType === 'new') {
      return (
        <div className={classes.passwordContainer}>
          <BasicInput
            title={
              this.props.usageType === 'new'
                ? this.props.t('MAIN_FETCH_PASSWORD_TITLE') + ' *'
                : this.props.t('MAIN_FETCH_PASSWORD_TITLE')
            }
            placeholder={this.props.t('MAIN_FETCH_PASSWORD_PLACEHOLDER')}
            onChange={(value) => this.onGameChanged('password', value)}
            value={this.props.game.password}
            password
            marginBottom
          />
          <BasicInput
            placeholder={this.props.t('MAIN_FETCH_PASSWORD_PLACEHOLDER_RETYPE')}
            onChange={(value) => this.props.changeRetypedPassword(value)}
            value={this.props.retypedPassword}
            password
          />
        </div>
      );
    }
  }

  renderLink() {
    if (this.props.usageType !== 'updateResults') {
      return (
        <BasicInput
          title={this.props.t('NEW_LINK')}
          placeholder={this.props.t('NEW_LINK_PLACEHOLDER')}
          value={this.props.game.link}
          onChange={(value) => this.onGameChanged('link', value)}
          disabled={this.props.usageType === 'updateResults'}
        />
      );
    }
  }

  renderDescription() {
    if (this.props.usageType !== 'updateResults') {
      return (
        <BasicInput
          title={this.props.t('NEW_DESCRIPTION')}
          placeholder={this.props.t('NEW_DESCRIPTION_PLACEHOLDER')}
          multiline
          rows={5}
          value={this.props.game.description}
          onChange={(value) => this.onGameChanged('description', value)}
          disabled={this.props.usageType === 'updateResults'}
          marginBottom={this.props.usageType !== 'new'}
        />
      );
    }
  }

  renderCheckboxIcon(checked) {
    if (checked) {
      return <MdCheckBox size={30} style={{ marginRight: 10 }} />;
    }
    return <MdCheckBoxOutlineBlank size={30} style={{ marginRight: 10 }} />;
  }

  renderShowAllTargetsCheckbox() {
    if (this.props.usageType !== 'new') {
      return (
        <CheckBox
          onClick={this.onShowAllTargetsPressed.bind(this)}
          title={this.props.t('SHOW_ALL_TARGETS')}
          noBottomMargin
        >
          {this.renderCheckboxIcon(this.props.showAllTargets)}
        </CheckBox>
      );
    }
  }

  render() {
    return (
      <div ref={this.containerRef} className={classes.container}>
        {this.renderInvalidWarnings()}
        <BasicInput
          title={this.props.usageType === 'new' ? this.props.t('NEW_TITLE') + ' *' : this.props.t('NEW_TITLE')}
          placeholder={this.props.t('NEW_TITLE_PLACEHOLDER')}
          value={this.props.game.name}
          onChange={(value) => this.onGameChanged('title', value)}
          disabled={this.props.usageType === 'updateResults'}
          marginBottom={this.props.usageType === 'updateResults'}
          multiline
        />
        {this.renderLink()}
        {this.renderDescription()}
        {this.renderPassword()}
        {this.renderShowAllTargetsCheckbox()}
        {this.renderTargetCluster()}
        {this.renderTargets()}
        {this.renderAddTargetButton()}
        {this.renderPublish()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    invalidTournament,
    usageType,
    participants,
    game,
    unpublishedChanges,
    showAllTargets,
  } = state.tournament;
  return {
    invalidTournament,
    usageType,
    participants,
    game,
    unpublishedChanges,
    showAllTargets,
  };
};

export default connect(mapStateToProps, {
  createNewTarget,
  publish,
  changeRetypedPassword,
  updateGame,
  deleteGame,
  expandTargets,
  toggleShowAllTargets,
})(withTranslation()(Tournament));
