import React from 'react';
import classes from './Title.module.css';

const Title = ({ title, noTopMargin = false }) => {
  if (title) {
    return (
      <h1
        className={[classes.Title, noTopMargin ? classes.noTopMargin : ''].join(
          ' ',
        )}
      >
        {title}
      </h1>
    );
  }
  return null;
};

export default Title;
