import React, { useEffect, useRef } from 'react';
import classes from './Textarea.module.css';

const TextArea = ({ disabled, value, onChange, placeholder, rows = 1 }) => {
  const ref = useRef();
  useEffect(() => {
    const { current } = ref;
    let calcRows = Math.floor((current.scrollHeight - 20) / 19);
    current.rows = calcRows > rows ? calcRows : rows;
    const handleOnTextAreaChanged = () => {
      current.rows = rows > 1 ? rows : 1;
      calcRows = Math.floor((current.scrollHeight - 20) / 19);
      current.rows = calcRows > rows ? calcRows : rows;
    };
    current && current.addEventListener('input', handleOnTextAreaChanged);

    return () => {
      current && current.removeEventListener('input', handleOnTextAreaChanged);
    };
  });

  return (
    <textarea
      ref={ref}
      className={classes.TextArea}
      rows={rows}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default TextArea;
