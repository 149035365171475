import React from 'react';
import Title from './common/Title';
import mapPropsToStyles from './common/mapPropsToStyles';
import classes from './BasicButton.module.css';

const BasicButton = ({
  onClick,
  children,
  doubleRowRight,
  doubleRowLeft,
  disabled,
  title,
  darkButton = false,
  selected = false,
  linkButton = false,
  marginTopButton = false,
  mobileSave = false,
  mobileMenubarVisible = false,
}) => {
  return (
    <div
      className={mapPropsToStyles(
        [classes.container],
        {
          linkButton,
          marginTopButton,
          doubleRowRight,
          doubleRowLeft,
          mobileSaveContainer: mobileSave,
          mobileMenubarVisible,
        },
        classes,
      )}
    >
      <Title title={title} />
      <button
        type="button"
        className={mapPropsToStyles(
          [classes.button],
          {
            dark: darkButton || selected,
            mobileSaveButton: mobileSave,
          },
          classes,
        )}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    </div>
  );
};

export default BasicButton;
