import React from 'react';
import { useTranslation } from 'react-i18next';
import Input from './common/Input';
import Title from './common/Title';
import classes from './ResultOption.module.css';

const ResultScore = ({ title, home, visitor, value, onChanged }) => {
  const { t } = useTranslation();
  const useTitle = title || t('TARGET_RESULT_SCORE_TITLE');
  return (
    <div>
      <h1 className={classes.title}>{useTitle}</h1>
      <div className={classes.container}>
        <Title title={t('TARGET_RESULT_SCORE_HOME')} />
        <Input
          integer
          resultScore
          value={home}
          onChange={(newVal) => onChanged(newVal, visitor, value)}
        />
        <Title title={t('TARGET_RESULT_SCORE_VISITOR')} />
        <Input
          integer
          resultScore
          value={visitor}
          onChange={(newVal) => onChanged(home, newVal, value)}
        />
        <Title title={t('TARGET_RESULTS_WIN')} />
        <Input
          decimal
          resultScore
          value={value}
          onChange={(newVal) => onChanged(home, visitor, newVal)}
        />
      </div>
    </div>
  );
};

export default ResultScore;
