import React, { Suspense } from 'react';
import { useDispatch } from 'react-redux';
import changeLanguage from './actions/ChangeLanguageAction';
import Header from './components/Header';
import OrganizerMain from './containers/OrganizerMain';
import Spinner from './components/common/Spinner';

const App = () => {
  const dispatch = useDispatch();

  const onChangeLanguage = (selected) => {
    dispatch(changeLanguage(selected));
  };

  return (
    <Suspense fallback={<Spinner />}>
      <Header onChangeLanguage={onChangeLanguage} />
      <OrganizerMain />
    </Suspense>
  );
};

export default App;
