

const CONSOLE_LOG_FETCH_REQUEST_URL = true;
const CONSOLE_LOG_FETCH_REQUEST_PARAMS = true;
const CONSOLE_LOG_FETCH_RESPONSE = true;
const CONSOLE_LOG_FETCH_RESPONSE_DATA = true;
const CONSOLE_LOG_FETCH_ERROR = true;
const AWS_RETRY_COUNT = 2;

export const fetchAws = (url, dispatch, callback, method, body, logAll) => {
    let tryCount = AWS_RETRY_COUNT;
    tryFetchAws(url, dispatch, callback, undefined, tryCount, method, body, logAll);
}

export const fetchAwsWithFailure = (url, dispatch, callback, failureCallback, tryCount, method, body) => {
    let count = tryCount !== undefined ? tryCount : AWS_RETRY_COUNT;
    tryFetchAws(url, dispatch, callback, failureCallback, count, method, body, undefined);
}

const tryFetchAws = (url, dispatch, callback, failureCallback, tryCount, method, body, logAll) => {
    //id logging is wanted to enable without method and body defined
    if (method === true) {
        method = 'GET'
        logAll = true;
    }
    const timestamp = Date.now();
    if (CONSOLE_LOG_FETCH_REQUEST_URL || logAll) console.log(url, 'ID: ' + timestamp);
    let fetchParams = {
        method: method === undefined ? 'GET' : method,
        headers: {
           Accept: 'application/json',
           'Content-Type': 'application/json'
        }
    };
    if (body !== undefined) {
        fetchParams.body = body;
    }
    if (CONSOLE_LOG_FETCH_REQUEST_PARAMS || logAll) console.log(fetchParams);
    fetch(url, fetchParams)
    .then((response) => {
        if (CONSOLE_LOG_FETCH_RESPONSE || logAll) console.log(response, 'ID: ' + timestamp + ' Duration in ms: ' + (Date.now() - timestamp));
        if (response.status < 400) {
            return response.json();
        } else if (response.status === 410) {
            //dispatch({ type: ACTIVE_SCENE_CHANGED, payload: 'StartOver' });
        } else if (tryCount === 0) {
            if (failureCallback !== undefined ) {
                failureCallback(response.status, dispatch);
            } else {
                //dispatch({ type: ACTIVE_SCENE_CHANGED, payload: 'TryAgain' });
            }
        } else {
            tryFetchAws(url, dispatch, callback, failureCallback, tryCount-1, method, body, logAll);
        }
        return 'notReady'
    })
    .then((responseData) => {
        if (responseData !== 'notReady') {
            if (CONSOLE_LOG_FETCH_RESPONSE_DATA || logAll) console.log(responseData);
            callback(responseData, dispatch);
        }
    })
    .catch((error) => {
        if (CONSOLE_LOG_FETCH_ERROR || logAll) console.log(error);
        //dispatch({ type: ACTIVE_SCENE_CHANGED, payload: 'TryAgain' });
    });
}
