import React from 'react';
import Title from './common/Title';
import BasicButton from './BasicButton';
import classes from './Link.module.css';

const Link = ({ title, headline, buttonText }) => {
  const renderCopyButton = () => {
    if (buttonText) {
      return (
        <BasicButton
          onClick={() => {
            navigator.clipboard.writeText(headline);
          }}
          linkButton
        >
          {buttonText}
        </BasicButton>
      );
    }
    return null;
  };

  return (
    <div className={classes.container}>
      <Title title={title} />
      <h1 className={classes.headline}>
        <a className={classes.link} href={headline}>
          {headline}
        </a>
      </h1>
      {renderCopyButton()}
    </div>
  );
};

export default Link;
