import React from 'react';
import Input from './common/Input';
import Textarea from './common/Textarea';
import Title from './common/Title';
import mapPropsToStyles from './common/mapPropsToStyles';
import classes from './BasicInput.module.css';

const BasicInput = ({
  title,
  placeholder,
  multiline = false,
  rows = 1,
  value,
  onChange = () => {},
  disabled = false,
  password = false,
  marginBottom = false,
  titleNoTopMargin = false,
}) => {
  if (multiline) {
    return (
      <div
        className={mapPropsToStyles(
          [classes.container],
          { marginBottom },
          classes,
        )}
      >
        <Title title={title} noTopMargin={titleNoTopMargin} />
        <Textarea
          placeholder={placeholder}
          rows={rows}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    );
  }
  return (
    <div
      className={mapPropsToStyles(
        [classes.container],
        { marginBottom, passwordInput: password },
        classes,
      )}
    >
      <Title title={title} noTopMargin={titleNoTopMargin} />
      <Input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        password={password}
      />
    </div>
  );
};

export default BasicInput;
