import React from 'react';
import { useTranslation } from 'react-i18next';
import Input from './common/Input';
import classes from './InputOddOption.module.css';

const InputOddOption = ({
  index,
  placeholder,
  title,
  onChange,
  optionValue,
  value,
  onValueChanged,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className={classes.title}>{title}</h1>
      <div className={classes.container}>
        <Input
          placeholder={placeholder}
          value={optionValue}
          onChange={onChange}
          disabled={disabled}
        />
        <h1 className={classes.title2}>{t('TARGET_RESULTS_ODD')}</h1>
        <Input
          decimal
          inputOddOption
          value={value}
          onChange={(newVal) => onValueChanged(newVal, index)}
        />
      </div>
    </>
  );
};

export default InputOddOption;
