/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable radix */
import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';
import DatePickerClosingDateButton from './DatePickerClosingDateButton';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

const DatePicker = ({ closingDate, onChange, placeholder }) => {
  registerLocale('enGB', enGB);

  return (
    <ReactDatePicker
      selected={closingDate ? new Date(parseInt(closingDate)) : null}
      onChange={(date) => onChange(new Date(date).getTime())}
      showTimeSelect
      locale="enGB"
      dateFormat="yyyy/MM/dd, H:mm"
      placeholderText={placeholder}
      customInput={<DatePickerClosingDateButton />}
    />
  );
};

export default DatePicker;
