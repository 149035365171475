import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './Terms.module.css';

const Terms = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.textContainer}>
          <h2 className={classes.title}>{t('TERMS_TITLE')}</h2>
          <p className={classes.text}>{t('TERMS_BODY_1')}</p>
          <p className={classes.text}>{t('TERMS_BODY_2')}</p>
          <p className={classes.text}>{t('TERMS_BODY_3')}</p>
          <p className={classes.text}>{t('TERMS_BODY_4')}</p>
          <p className={classes.text}>{t('TERMS_BODY_5')}</p>
          <p className={classes.text}>{t('TERMS_BODY_6')}</p>
          <p className={classes.text}>{t('TERMS_BODY_7')}</p>
          <p className={classes.text}>{t('TERMS_BODY_8')}</p>
          <h2 className={classes.title}>{t('TERMS_POLICY_TITLE')}</h2>
          <p className={classes.text}>{t('TERMS_POLICY_BODY_1')}</p>
          <p className={classes.text}>{t('TERMS_POLICY_BODY_2')}</p>
          <p className={classes.text}>{t('TERMS_POLICY_BODY_3')}</p>
          <p className={classes.text}>{t('TERMS_POLICY_BODY_4')}</p>
          <h2 className={classes.title}>{t('TERMS_COOKIES_TITLE')}</h2>
          <p className={classes.text}>{t('TERMS_COOKIES_BODY_1')}</p>
          <p className={classes.text + ' ' + classes.nobottommargin}>
            {t('TERMS_COOKIES_BODY_2')}
          </p>
          <a href="https://policies.google.com/privacy/partners?hl=en">here</a>
          <p className={classes.text + ' ' + classes.topmargin}>
            {t('TERMS_COOKIES_BODY_3')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
