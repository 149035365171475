import React from 'react';
import Title from './common/Title';
import classes from './Headline.module.css';

const Headline = ({ title, headline }) => {
  return (
    <div className={classes.container}>
      <Title title={title} className={classes.title} />
      <h1 className={classes.headline}>{headline}</h1>
    </div>
  );
};

export default Headline;
