export const NEW_TARGET_CREATED = 'new_target_created';
export const TARGET_REMOVED = 'target_removed';
export const TARGET_CHANGED = 'target_changed';
export const RESET_REDUCERS = 'reset_reducers';
export const INVALID_TOURNAMENT = 'invalid_tournament';
export const CODE_FETCHED = 'code_fetched';
export const RETYPED_PASSWORD_CHANGED = 'retyped_password_changed';
export const TOURNAMENT_PUBLISHED = 'tournament_published';
export const GAME_CHANGED = 'game_changed';
export const USER_PASSWORD_CHANGED = 'user_password_changed';
export const USER_DELETED = 'user_deleted';
export const USER_SELECTED = 'user_selected';
export const START_CREATING = 'start_creating';
export const NAVIGATED_TO = 'navigate_to';
export const COPIED_GAME = 'copied_game';
export const LOADING = 'loading';
export const DISCARD_CHANGES = 'discard_changes';
export const TARGET_EXPANDED = 'target_expanded';
export const SHOW_ALL_TARGETS = 'show_all_targets';
export const COPIED_NEW_TARGET = 'copied_new_target';
