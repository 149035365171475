import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './Guide.module.css';

const Guide = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.textContainer}>
          <h2 className={classes.title}>{t('GUIDE_QUICK_GUIDE_TITLE')}</h2>
          <p className={classes.text + ' ' + classes.nomargin}>
            {t('GUIDE_QUICK_GUIDE_STEP_1')}
          </p>
          <p className={classes.text + ' ' + classes.nomargin}>
            {t('GUIDE_QUICK_GUIDE_STEP_2')}
          </p>
          <p className={classes.text + ' ' + classes.nomargin}>
            {t('GUIDE_QUICK_GUIDE_STEP_3')}
          </p>
          <p className={classes.text + ' ' + classes.nomargin}>
            {t('GUIDE_QUICK_GUIDE_STEP_4')}
          </p>
          <p className={classes.text}>{t('GUIDE_QUICK_GUIDE_STEP_5')}</p>
          <h2 className={classes.title}>{t('GUIDE_PURPOSE_TITLE')}</h2>
          <p className={classes.text}>{t('GUIDE_PURPOSE_BODY')}</p>
          <p className={classes.text}>{t('GUIDE_PURPOSE_BODY_2')}</p>
          <h2 className={classes.title}>{t('GUIDE_BENEFITS_TITLE')}</h2>
          <ul className={classes.litextcontainer}>
            <li className={classes.litext}>{t('GUIDE_BENEFITS_POINT_0')}</li>
            <li className={classes.litext}>{t('GUIDE_BENEFITS_POINT_1')}</li>
            <li className={classes.litext}>{t('GUIDE_BENEFITS_POINT_2')}</li>
            <li className={classes.litext}>{t('GUIDE_BENEFITS_POINT_3')}</li>
            <li className={classes.litext}>{t('GUIDE_BENEFITS_POINT_4')}</li>
            <li className={classes.litext}>{t('GUIDE_BENEFITS_POINT_5')}</li>
          </ul>
          <h2 className={classes.title}>{t('GUIDE_CREATE_TITLE')}</h2>
          <p className={classes.text}>{t('GUIDE_CREATE_BODY_1')}</p>
          <p className={classes.text}>{t('GUIDE_CREATE_BODY_2')}</p>
          <p className={classes.text}>{t('GUIDE_CREATE_BODY_2A')}</p>
          <p className={classes.text}>{t('GUIDE_CREATE_BODY_3')}</p>
          <h2 className={classes.title}>{t('GUIDE_TARGET_TITLE')}</h2>
          <p className={classes.text}>{t('GUIDE_TARGET_BODY_1')}</p>
          <p className={classes.text}>{t('GUIDE_TARGET_BODY_2')}</p>
          <p className={classes.text}>{t('GUIDE_TARGET_BODY_4')}</p>
          <ul className={classes.litextcontainer}>
            <li className={classes.litext}>{t('GUIDE_TARGET_TYPE_1')}</li>
            <li className={classes.litext}>{t('GUIDE_TARGET_TYPE_2')}</li>
            <li className={classes.litext}>{t('GUIDE_TARGET_TYPE_3')}</li>
            <li className={classes.litext}>{t('GUIDE_TARGET_TYPE_4')}</li>
          </ul>
          <p className={classes.text}>{t('GUIDE_TARGET_BODY_5')}</p>
          <p className={classes.text}>{t('GUIDE_TARGET_BODY_9')}</p>
          <p className={classes.text}>{t('GUIDE_TARGET_BODY_3')}</p>
          <p className={classes.text}>{t('GUIDE_TARGET_BODY_10')}</p>
          <p className={classes.text}>{t('GUIDE_TARGET_BODY_8')}</p>
          <h2 className={classes.title}>{t('GUIDE_MODIFY_TITLE')}</h2>
          <p className={classes.text}>{t('GUIDE_MODIFY_BODY')}</p>
          <p className={classes.text}>{t('GUIDE_MODIFY_BODY_2')}</p>
          <p className={classes.text}>{t('GUIDE_MODIFY_BODY_3')}</p>
          <p className={classes.text}>{t('GUIDE_MODIFY_BODY_4')}</p>
          <h2 className={classes.title}>{t('GUIDE_UPDATE_RESULTS_TITLE')}</h2>
          <p className={classes.text}>{t('GUIDE_UPDATE_RESULTS_BODY_1')}</p>
          <p className={classes.text}>{t('GUIDE_UPDATE_RESULTS_BODY_2')}</p>
          <p className={classes.text}>{t('GUIDE_UPDATE_RESULTS_BODY_3')}</p>
          <h2 className={classes.title}>{t('GUIDE_NAVIGATE_TARGETS_TITLE')}</h2>
          <p className={classes.text}>{t('GUIDE_NAVIGATE_TARGETS_BODY_1')}</p>
          <p className={classes.text}>{t('GUIDE_NAVIGATE_TARGETS_BODY_2')}</p>
          <h2 className={classes.title}>{t('GUIDE_SHARE_TITLE')}</h2>
          <p className={classes.text}>{t('GUIDE_SHARE_BODY_1')}</p>
          <p className={classes.text}>{t('GUIDE_SHARE_BODY_2')}</p>
          <p className={classes.text}>{t('GUIDE_SHARE_BODY_3')}</p>
          <h2 className={classes.title}>{t('GUIDE_USER_CONTROL_TITLE')}</h2>
          <p className={classes.text}>{t('GUIDE_USER_CONTROL_BODY_1')}</p>
          <p className={classes.text}>{t('GUIDE_USER_CONTROL_BODY_2')}</p>
          <p className={classes.text}>{t('GUIDE_USER_CONTROL_BODY_3')}</p>
          <h2 className={classes.title}>{t('GUIDE_USER_VIEW_TITLE')}</h2>
          <p className={classes.text}>{t('GUIDE_USER_VIEW_BODY')}</p>
          <h2 className={classes.title}>{t('GUIDE_DELETE_TITLE')}</h2>
          <p className={classes.text}>{t('GUIDE_DELETE_BODY')}</p>
          <h2 className={classes.title}>{t('GUIDE_COPY_TITLE')}</h2>
          <p className={classes.text}>{t('GUIDE_COPY_BODY')}</p>
          <h2 className={classes.title}>{t('GUIDE_EXPORT_TITLE')}</h2>
          <p className={classes.text}>{t('GUIDE_EXPORT_BODY')}</p>
          <h2 className={classes.title}>{t('GUIDE_IMPORT_TITLE')}</h2>
          <p className={classes.text}>{t('GUIDE_IMPORT_BODY')}</p>
        </div>
      </div>
    </div>
  );
};

export default Guide;
