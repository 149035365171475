import React from 'react';
import classes from './IconButton.module.css';

const IconButton = ({
  children,
  onClick,
  disabled = false,
  fade = false,
  selectedMenu = false,
}) => (
  <button
    type="button"
    className={[
      classes.Button,
      fade ? classes.FadeIcon : '',
      selectedMenu ? classes.CurrentMenuItem : '',
    ].join(' ')}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

export default IconButton;
