import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  FaUsers,
  FaShareAlt,
  FaEdit,
  FaPlusSquare,
  FaPoll,
  FaArrowCircleRight,
} from 'react-icons/fa';
import * as TournamentActions from '../actions/TournamentActions';
import IconButton from '../components/common/IconButton';
import classes from './MobileMenubar.module.css';

const MobileMenubar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { usageType } = useSelector((state) => state.tournament);

  const onNavigate = (to) => {
    dispatch(TournamentActions.navigateTo(to));
  };

  const renderTitle = (title, scene) => (
    <h4
      className={[
        classes.Title,
        usageType === scene ? '' : classes.HideTitle,
      ].join(' ')}
    >
      {title}
    </h4>
  );

  const renderIcon = (type) => {
    switch (type) {
      case 'modify':
        return <FaEdit />;
      case 'updateResults':
        return <FaPoll />;
      case 'shareInfo':
        return <FaShareAlt />;
      case 'userControl':
        return <FaUsers />;
      case 'participantView':
        return <FaArrowCircleRight />;
      default:
        return <FaPlusSquare />;
    }
  };

  const renderMenuIcon = (type, title) => {
    return (
      <IconButton
        onClick={() => onNavigate(type)}
        fade={usageType !== type}
        selectedMenu={usageType === type}
      >
        {renderIcon(type)}
        {renderTitle(title, type)}
      </IconButton>
    );
  };

  if (usageType === 'start' || usageType === 'new' || usageType === 'login') {
    return null;
  }

  return (
    <div className={classes.Container}>
      {renderMenuIcon('modify', t('MAIN_MODIFY'))}
      {renderMenuIcon('updateResults', t('MAIN_UPDATE_RESULTS'))}
      {renderMenuIcon('shareInfo', t('MAIN_SHARE_INFO'))}
      {renderMenuIcon('userControl', t('MAIN_USER_CONTROL'))}
      {renderMenuIcon('participantView', t('USER_VIEW'))}
    </div>
  );
};

export default MobileMenubar;
