import {
  NEW_TARGET_CREATED,
  TARGET_REMOVED,
  TARGET_CHANGED,
  INVALID_TOURNAMENT,
  CODE_FETCHED,
  RESET_REDUCERS,
  RETYPED_PASSWORD_CHANGED,
  TOURNAMENT_PUBLISHED,
  GAME_CHANGED,
  USER_PASSWORD_CHANGED,
  USER_DELETED,
  USER_SELECTED,
  START_CREATING,
  NAVIGATED_TO,
  COPIED_GAME,
  LOADING,
  DISCARD_CHANGES,
  TARGET_EXPANDED,
  SHOW_ALL_TARGETS,
  COPIED_NEW_TARGET,
} from '../actions/types';

const INITIAL_STATE = {
  usageType: 'start',
  participants: false,
  lockedUntil: -1,
  retypedPassword: '',
  generatingTournament: false,
  game: {
    id: '',
    password: '',
    name: '',
    description: '',
    link: '',
    allowSignIn: true,
    targets: [],
  },
  invalidTournament: {
    name: false,
    link: false,
    target: false,
    targetName: false,
    targetFirst: false,
    targetSecond: false,
    targetDeadline: false,
    targetBetResults: false,
    targetOptions: false,
  },
  selectedUser: false,
  fetchVisible: false,
  loading: true,
  unpublishedChanges: false,
  showAllTargets: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };
    case GAME_CHANGED:
      return {
        ...state,
        unpublishedChanges: true,
        game: JSON.parse(JSON.stringify(action.payload)),
      };
    case TARGET_EXPANDED:
      return {
        ...state,
        game: JSON.parse(JSON.stringify(action.payload)),
      };
    case COPIED_NEW_TARGET:
      state.game.targets.push(action.payload);
      return {
        ...state,
        unpublishedChanges: true,
        game: JSON.parse(JSON.stringify(state.game)),
      };
    case NEW_TARGET_CREATED:
      state.game.targets.push({
        name: '',
        links: { first: '', second: '' },
        type: 'result',
        bet1x2: true,
        options: ['', ''],
        bet_result: { from: 0, to: 20 },
        isOdds: true,
        odds: ['', '', ''],
        open: true,
      });
      return {
        ...state,
        unpublishedChanges: true,
        game: JSON.parse(JSON.stringify(state.game)),
      };
    case TARGET_REMOVED:
      if (state.game.targets.length > action.payload +1) {
        state.game.targets[action.payload + 1].open = true;
      } else if (action.payload > 0) {
        state.game.targets[action.payload - 1].open = true;
      }
      state.game.targets.splice(action.payload, 1);
      return {
        ...state,
        unpublishedChanges: true,
        game: JSON.parse(JSON.stringify(state.game)),
      };
    case TARGET_CHANGED:
      state.game.targets[action.payload.id - 1] = action.payload;
      return {
        ...state,
        unpublishedChanges: true,
        game: JSON.parse(JSON.stringify(state.game)),
      };
    case INVALID_TOURNAMENT:
      console.log(action.payload);
      return {
        ...state,
        invalidTournament: JSON.parse(JSON.stringify(action.payload)),
      };
    case CODE_FETCHED:
      return {
        ...state,
        unpublishedChanges: false,
        usageType: action.payload.usageType,
        fetchVisible: false,
        game: action.payload.game,
        participants: action.payload.participants,
        lockedUntil:
          action.payload.participants.length > 0
            ? action.payload.game.targets.length - 1
            : -1,
      };
    case RETYPED_PASSWORD_CHANGED:
      return { ...state, retypedPassword: action.payload };
    case TOURNAMENT_PUBLISHED: {
      return {
        ...state,
        game: action.payload,
        unpublishedChanges: false,
        invalidTournament: INITIAL_STATE.invalidTournament,
      };
    }
    case USER_DELETED: {
      const participants = JSON.parse(JSON.stringify(state.participants));
      for (let i = 0; i < participants.length; i++) {
        if (participants[i].name === action.payload) {
          participants.splice(i, 1);
        }
      }
      return { ...state, participants };
    }
    case USER_PASSWORD_CHANGED:
      return { ...state, selectedUser: false };
    case USER_SELECTED:
      return {
        ...state,
        selectedUser:
          state.selectedUser === action.payload ? false : action.payload,
      };
    case START_CREATING:
      return {
        ...state,
        game: INITIAL_STATE.game,
        participants: false,
        lockedUntil: -1,
        invalidTournament: INITIAL_STATE.invalidTournament,
        retypedPassword: '',
        usageType: 'new',
      };
    case NAVIGATED_TO:
      if (state.game.id && state.game.password) {
        return { ...state, usageType: action.payload, fetchVisible: false };
      }
      if (action.payload === 'start') {
        return {
          ...state,
          usageType: state.usageType === 'new' ? state.usageType : 'start',
          fetchVisible: false,
        };
      }
      return { ...state, usageType: action.payload, fetchVisible: true };
    case COPIED_GAME:
      return {
        ...state,
        usageType: 'new',
        game: action.payload,
        participants: false,
        lockedUntil: -1,
        invalidTournament: INITIAL_STATE.invalidTournament,
        retypedPassword: '',
      };
    case SHOW_ALL_TARGETS:
      return { ...state, showAllTargets: action.payload };
    case DISCARD_CHANGES:
      return { ...state, unpublishedChanges: false };
    case RESET_REDUCERS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
