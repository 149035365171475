import React from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';
import classes from './AddRemoveButtons.module.css';

const AddRemoveButtons = ({
  title,
  addable = true,
  onlyRemovableTitle = '',
  removable,
  onAddPressed,
  onRemovePressed,
}) => {
  const renderRemoveOptionButton = () => {
    if (removable) {
      return (
        <button
          type="button"
          className={classes.removeButton}
          onClick={onRemovePressed}
        >
          <MdRemove size={30} />
        </button>
      );
    }
    return null;
  };

  const visibility = addable ? 'visible' : 'hidden';

  return (
    <div className={classes.addRemoveContainer}>
      <button
        type="button"
        className={classes.addButton}
        onClick={addable ? onAddPressed : onRemovePressed}
      >
        <MdAdd size={30} style={{ marginRight: 15, visibility }} />
        {addable ? title : onlyRemovableTitle}
      </button>
      {renderRemoveOptionButton()}
    </div>
  );
};

export default AddRemoveButtons;
