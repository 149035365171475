import React from 'react';
import { useTranslation } from 'react-i18next';
import ChooseFlag from './ChooseFlag';
import classes from './Header.module.css';
import { LANGUAGES_TO_FLAGS } from '../Constants';

const Header = ({ onChangeLanguage }) => {
  const { t, i18n } = useTranslation();

  const onSelected = (selectedFlag) => {
    const languageCode = Object.keys(LANGUAGES_TO_FLAGS).find(
      (key) => LANGUAGES_TO_FLAGS[key] === selectedFlag,
    );
    onChangeLanguage(languageCode);
  };

  const currentLanguage = Object.keys(LANGUAGES_TO_FLAGS).find((key) =>
    i18n.language.includes(key),
  );

  return (
    <header className={classes.Header}>
      <div className={classes.TitleContainer}>
        <img
          className={classes.Mainlogo}
          src="logo.png"
          width="55"
          heigth="55"
          alt="FriendlyBet logo"
        />
        <div>
          <h1 className={classes.Title} id="main-title">
            {t('HEADER_TITLE')}
          </h1>
          <h4>{t('HEADER_SECONDARY_TITLE')}</h4>
        </div>
      </div>
      <ChooseFlag
        selected={LANGUAGES_TO_FLAGS[currentLanguage]}
        options={Object.values(LANGUAGES_TO_FLAGS)}
        onSelected={onSelected}
      />
    </header>
  );
};

export default Header;
