import React, { forwardRef } from 'react';

const ClosingDateButton = ({ onClick, placeholder, value }, ref) => {
  return (
    <button
      type="button"
      className="closing-date-button"
      onClick={onClick}
      placeholder={placeholder}
    >
      {!value ? placeholder : value}
    </button>
  );
};

export default forwardRef(ClosingDateButton);
