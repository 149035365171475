const mapPropsToStyles = (baseClasses = [], props = {}, styles = {}) => {
  const cssKeys = Object.keys(styles);
  const selectedCssKeys = Object.keys(props).filter(
    (prop) => props[prop] && cssKeys.includes(prop),
  );
  const selectedClasses = selectedCssKeys.map((key) => styles[key]);
  return baseClasses.concat(selectedClasses).join(' ');
};

export default mapPropsToStyles;
