import React, { useEffect, useRef } from 'react';
import classes from './Input.module.css';

const Input = ({
  disabled,
  value,
  onChange,
  placeholder,
  decimal = false,
  integer = false,
  password = false,
  resultOption = false,
  resultScore = false,
  ScoreLimits = false,
  inputOddOption = false,
}) => {
  const inputRef = useRef();
  useEffect(() => {
    const { current } = inputRef;

    const closeKeyboardAndFocus = (event) => {
      if (event.keyCode === 13) {
        document.activeElement.blur();
      }
    };
    current && current.addEventListener('keyup', closeKeyboardAndFocus);

    return () => {
      current && current.removeEventListener('keyup', closeKeyboardAndFocus);
    };
  });

  const onTrimChange = (event) => {
    if (decimal) {
      onChange(event.target.value.replace(/[^0-9,.]/, '').replace(',', '.'));
    } else if (integer) {
      onChange(event.target.value.replace(/\D/, ''));
    } else {
      onChange(event.target.value);
    }
  };

  return (
    <input
      ref={inputRef}
      type={password ? 'password' : decimal || integer ? 'tel' : 'text'}
      className={[
        classes.Input,
        resultOption ? classes.resultOption : '',
        resultScore ? classes.resultScore : '',
        ScoreLimits ? classes.ScoreLimits : '',
        inputOddOption ? classes.InputOddOption : '',
      ].join(' ')}
      value={value}
      onChange={onTrimChange}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default Input;
