import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaUserCog } from 'react-icons/fa';
import * as TournamentActions from '../actions/TournamentActions';
import mapPropsToStyles from '../components/common/mapPropsToStyles';
import classes from './Menubar.module.css';

const Menubar = () => {
  const { t } = useTranslation();
  const [settingsOpen, toggleSettings] = useState(false);
  const dispatch = useDispatch();
  const { usageType } = useSelector((state) => state.tournament);

  const onNavigate = (to) => {
    dispatch(TournamentActions.navigateTo(to));
  };

  const onDeleteGame = () => {
    toggleSettings(!settingsOpen);
    dispatch(TournamentActions.deleteGame());
  };

  const onCreateCopy = () => {
    toggleSettings(!settingsOpen);
    dispatch(TournamentActions.startFromCopy());
  };

  const onExportGame = () => {
    toggleSettings(!settingsOpen);
    dispatch(TournamentActions.exportGame());
  };

  const onLogout = () => {
    toggleSettings(!settingsOpen);
    dispatch(TournamentActions.resetAll());
  };

  const renderMenuButton = (type, title, hideMobile) => {
    return (
      <button
        type="button"
        className={mapPropsToStyles(
          [classes.menuButton],
          {
            selectedMenuItem: usageType === type,
            hideMenuMobile: hideMobile,
          },
          classes,
        )}
        onClick={() => onNavigate(type)}
      >
        {title}
      </button>
    );
  };

  const renderSettingsMenu = () => {
    if (settingsOpen) {
      return (
        <div className={classes.settingMenu}>
          <button
            type="button"
            className={classes.settingsMenuButton}
            onClick={() => onLogout()}
          >
            {t('SETTINGS_MENU_LOGOUT')}
          </button>
          <button
            type="button"
            className={classes.settingsMenuButton}
            onClick={() => onCreateCopy()}
          >
            {t('COPY_GAME')}
          </button>
          <button
            type="button"
            className={classes.settingsMenuButton}
            onClick={() => onExportGame()}
          >
            {t('EXPORT_GAME')}
          </button>
          <button
            type="button"
            className={classes.settingsMenuButton}
            onClick={() => onDeleteGame()}
          >
            {t('DELETE_GAME')}
          </button>
        </div>
      );
    }
    return null;
  };

  const renderButtons = () => {
    if (usageType === 'start') {
      return (
        <>
          {renderMenuButton('start', t('MAIN_CREATE_BUTTON'), false)}
          {renderMenuButton('login', t('MAIN_LOGIN_BUTTON'), false)}
        </>
      );
    }
    if (usageType === 'new') {
      return (
        <>
          {renderMenuButton('new', t('MAIN_CREATE_BUTTON'), false)}
          {renderMenuButton('login', t('MAIN_LOGIN_BUTTON'), false)}
        </>
      );
    }
    if (usageType === 'login') {
      return (
        <>
          {renderMenuButton('start', t('MAIN_CREATE_BUTTON'), false)}
          {renderMenuButton('login', t('MAIN_LOGIN_BUTTON'), false)}
        </>
      );
    }
    return (
      <>
        {renderMenuButton('modify', t('MAIN_MODIFY'), true)}
        {renderMenuButton('updateResults', t('MAIN_UPDATE_RESULTS'), true)}
        {renderMenuButton('shareInfo', t('MAIN_SHARE_INFO'), true)}
        {renderMenuButton('userControl', t('MAIN_USER_CONTROL'), true)}
        {renderMenuButton('participantView', t('USER_VIEW'), true)}
        <button
          type="button"
          className={classes.menubarIcon}
          onClick={() => toggleSettings(!settingsOpen)}
        >
          <FaUserCog size={30} />
        </button>
        {renderSettingsMenu()}
      </>
    );
  };

  return <div className={classes.Container}>{renderButtons()}</div>;
};

export default Menubar;
