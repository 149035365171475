/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdDeleteForever,
  MdChevronLeft,
  MdChevronRight,
  MdContentCopy,
} from 'react-icons/md';
import mapPropsToStyles from '../components/common/mapPropsToStyles';
import classes from './Target.module.css';
import {
  updateTarget,
  removeTarget,
  expandTargets,
  changeTargetOrder,
  copyToNewTarget,
} from '../actions';
import Title from '../components/common/Title';
import ChooseBox from '../components/common/ChooseBox';
import BasicInput from '../components/BasicInput';
import InputOddOption from '../components/InputOddOption';
import CheckBox from '../components/CheckBox';
import DatePicker from '../components/DatePicker';
import ScoreLimits from '../components/ScoreLimits';
import ResultOption from '../components/ResultOption';
import ResultScore from '../components/ResultScore';
import AddRemoveButtons from '../components/AddRemoveButtons';
import InputWithSelection from '../components/InputWithSelection';

class Target extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      link1Open:
        this.props.content &&
        this.props.content.links &&
        (this.props.usageType === 'updateResults'
          ? this.props.content.links.result_first
          : this.props.content.links.first),
      link2Open:
        this.props.content &&
        this.props.content.links &&
        (this.props.usageType === 'updateResults'
          ? this.props.content.links.result_second
          : this.props.content.links.second),
    };
  }

  onTargetTypeSelection(type) {
    this.props.content.type = type;
    if (type === 'result') {
      this.props.content.bet1x2 = false;
      this.props.content.bet12 = false;
      this.props.content.score = false;
      this.props.content.options = ['', ''];
    } else if (type === 'choose') {
      this.props.content.score = false;
      this.props.content.bet1x2 = false;
      this.props.content.bet12 = false;
      this.props.content.bet_result = { from: 0, to: 20 };
      this.props.content.options = ['', ''];
    }
  }

  onCheckBoxPressed(selection) {
    if (selection === 'options' && this.props.content.type !== 'choose') {
      this.onTargetTypeSelection('choose');
    } else if (
      selection !== 'options' &&
      this.props.content.type === 'choose'
    ) {
      this.onTargetTypeSelection('result');
    } else if (selection === 'options') {
      this.onTargetTypeSelection('result');
      this.props.content.bet1x2 = true;
    }

    this.props.content.isOdds = true;

    if (selection === 'score' && !this.props.content[selection]) {
      this.props.content.bet_result = { from: 0, to: 20 };
    }

    if (selection === 'bet1x2' && !this.props.content.bet1x2) {
      this.props.content.bet12 = false;
    } else if (selection === 'bet12' && !this.props.content.bet12) {
      this.props.content.bet1x2 = false;
    } else if (
      selection === 'score' &&
      this.props.content.score &&
      !this.props.content.bet1x2 &&
      !this.props.content.bet12
    ) {
      this.props.content.bet1x2 = true;
    } else if (
      selection === 'bet1x2' &&
      this.props.content.bet1x2 &&
      !this.props.content.score &&
      !this.props.content.bet12
    ) {
      this.props.content.isOdds = false;
      this.props.content.score = true;
    } else if (
      selection === 'bet12' &&
      this.props.content.bet12 &&
      !this.props.content.score &&
      !this.props.content.bet1x2
    ) {
      this.props.content.bet1x2 = true;
    } else if (selection === 'score' && !this.props.content.score) {
      this.props.content.isOdds = false;
    }

    if (selection !== 'options') {
      this.props.content[selection] = !this.props.content[selection]
        ? true
        : false;
    }
    this.props.updateTarget(this.props.content);
  }

  onAddOptionPressed() {
    if (this.props.content.isOdds) {
      this.props.content.odds
        ? this.props.content.odds.push('')
        : (this.props.content.odds = ['']);
    }
    this.props.content.options
      ? this.props.content.options.push('')
      : (this.props.content.options = ['']);
    this.props.updateTarget(this.props.content);
  }

  onRemoveOptionPressed() {
    if (this.props.content.isOdds) {
      this.props.content.odds.pop();
    }
    this.props.content.options.pop();
    this.props.updateTarget(this.props.content);
  }

  onAddLinkPressed() {
    if (this.state.link1Open) {
      this.setState({ link2Open: true });
    } else {
      this.setState({ link1Open: true });
    }
  }

  onRemoveLinkPressed() {
    if (this.state.link2Open) {
      this.setState({ link2Open: false });
      if (this.props.usageType === 'updateResults') {
        this.props.content.links.result_second = '';
      } else {
        this.props.content.links.second = '';
      }
    } else {
      this.setState({ link1Open: false });
      if (this.props.usageType === 'updateResults') {
        this.props.content.links.result_first = '';
      } else {
        this.props.content.links.first = '';
      }
    }
    this.props.updateTarget(this.props.content);
  }

  onChangeOption(index, value) {
    this.props.content.options[index] = value;
    this.props.updateTarget(this.props.content);
  }

  onChangeName(value) {
    this.props.content.name = value;
    this.props.updateTarget(this.props.content);
  }

  onChangeHomeTeam(value) {
    this.props.content.home = value;
    this.props.updateTarget(this.props.content);
  }

  onChangeVisitorTeam(value) {
    this.props.content.visitor = value;
    this.props.updateTarget(this.props.content);
  }

  onChangeLink1(value) {
    this.props.content.links.first = value;
    this.props.updateTarget(this.props.content);
  }

  onChangeLink2(value) {
    this.props.content.links.second = value;
    this.props.updateTarget(this.props.content);
  }

  onChangeResultLink1(value) {
    this.props.content.links.result_first = value;
    this.props.updateTarget(this.props.content);
  }

  onChangeResultLink2(value) {
    this.props.content.links.result_second = value;
    this.props.updateTarget(this.props.content);
  }

  onRemoveTargetPressed() {
    this.props.removeTarget(this.props.index);
  }

  onClosingDateChanged(date) {
    this.props.content.deadline = date;
    this.props.updateTarget(this.props.content);
  }

  onFromChange(value) {
    this.props.content.bet_result.from = value;
    this.props.updateTarget(this.props.content);
  }

  onToChange(value) {
    this.props.content.bet_result.to = value;
    this.props.updateTarget(this.props.content);
  }

  onResultValueChange(value, index) {
    this.props.content.results.options[index] = value;
    this.props.updateTarget(this.props.content);
  }

  on1x2ResultValueChange(value) {
    if (
      this.props.content.bet1x2 &&
      this.props.content.results &&
      this.props.content.results._1x2 &&
      Object.keys(this.props.content.results._1x2).length > 0
    ) {
      this.props.content.results._1x2[
        Object.keys(this.props.content.results._1x2)[0]
      ] = value;
    } else if (
      this.props.content.bet12 &&
      this.props.content.results &&
      this.props.content.results._12 &&
      Object.keys(this.props.content.results._12).length > 0
    ) {
      this.props.content.results._12[
        Object.keys(this.props.content.results._12)[0]
      ] = value;
    }
    this.props.updateTarget(this.props.content);
  }

  onWinningValueChange(value, index) {
    this.props.content.odds[index] = value;
    this.props.updateTarget(this.props.content);
  }

  onScoreResultChange(home, visitor, value) {
    if (!this.props.content.results) {
      this.props.content.results = {};
    }
    if (!this.props.content.results.result) {
      this.props.content.results.result = {};
    }
    this.props.content.results.result.score = value;
    this.props.content.results.result.home = home;
    this.props.content.results.result.visitor = visitor;

    this.props.updateTarget(this.props.content);
  }

  onResultOptionPress(index) {
    if (!this.props.content.results) {
      this.props.content.results = {};
    }
    if (!this.props.content.results.options) {
      this.props.content.results.options = {};
    }
    if (this.props.content.results.options.hasOwnProperty(index)) {
      delete this.props.content.results.options[index];
    } else {
      this.props.content.results.options[index] =
        this.props.content.isOdds && this.props.content.odds
          ? this.props.content.odds[index]
          : 0;
    }
    this.props.updateTarget(this.props.content);
  }

  onResult1x2Press(selected, results) {
    if (!this.props.content.results) {
      this.props.content.results = {};
    }
    let value = '';
    if (this.props.content.isOdds && this.props.content.odds) {
      value = this.props.content.odds[
        selected === '1'
          ? 0
          : selected === '2' && this.props.content.bet1x2
          ? 2
          : 1
      ];
    } else if (results && Object.keys(results).length > 0) {
      value = results[Object.keys(results)[0]];
    }

    if (this.props.content.bet1x2) {
      this.props.content.results._1x2 = {};
      this.props.content.results._1x2[selected] = value;
    } else {
      this.props.content.results._12 = {};
      this.props.content.results._12[selected] = value;
    }
    this.props.updateTarget(this.props.content);
  }

  onPreviousPressed() {
    this.props.expandTargets('more', this.props.index - 1, true);
  }

  onNextPressed() {
    this.props.expandTargets('more', this.props.index + 1, true);
  }

  onCopyTargetPressed() {
    this.props.expandTargets('less', this.props.index, true);
    this.props.copyToNewTarget(this.props.content);
  }

  onChangeTargetOrder(from, to) {
    this.props.changeTargetOrder(from, to);
  }

  render1x2Odds() {
    if (
      this.props.usageType !== 'updateResults' &&
      this.props.content.type === 'result' &&
      this.props.content.isOdds &&
      !this.props.content.score
    ) {
      if (this.props.content.bet1x2) {
        return (
          <>
            {this.render1x2winning('1', 0)}
            {this.render1x2winning('X', 1)}
            {this.render1x2winning('2', 2)}
          </>
        );
      } else if (this.props.content.bet12) {
        return (
          <>
            {this.render1x2winning('1', 0)}
            {this.render1x2winning('2', 1)}
          </>
        );
      }
    }
  }

  render1x2winning(item, index) {
    return (
      <ResultOption
        index={index}
        title={item === '1' ? `${this.props.t('TARGET_WINNINGS')}:` : null}
        option={item}
        optionSelected
        disabled
        value={this.props.content.odds[index]}
        onValueChanged={this.onWinningValueChange.bind(this)}
      />
    );
  }

  renderScoreLimits() {
    if (this.props.content.score && this.props.usageType !== 'updateResults') {
      return (
        <ScoreLimits
          fromValue={this.props.content.bet_result.from}
          toValue={this.props.content.bet_result.to}
          onFromChange={this.onFromChange.bind(this)}
          onToChange={this.onToChange.bind(this)}
          disabled={this.props.index <= this.props.lockedUntil}
        />
      );
    }
  }

  render1x2Results() {
    if (this.props.usageType === 'updateResults') {
      if (this.props.content.bet1x2) {
        return (
          <>
            {this.render1x2result(
              0,
              '1',
              this.props.content.results
                ? this.props.content.results._1x2
                : null,
              this.props.content.isOdds && this.props.content.odds
                ? this.props.content.odds[0]
                : null,
              this.props.t('TARGET_RESULT_1X2_TITLE'),
            )}
            {this.render1x2result(
              1,
              'X',
              this.props.content.results
                ? this.props.content.results._1x2
                : null,
              this.props.content.isOdds && this.props.content.odds
                ? this.props.content.odds[1]
                : null,
            )}
            {this.render1x2result(
              2,
              '2',
              this.props.content.results
                ? this.props.content.results._1x2
                : null,
              this.props.content.isOdds && this.props.content.odds
                ? this.props.content.odds[2]
                : null,
            )}
          </>
        );
      } else if (this.props.content.bet12) {
        return (
          <>
            {this.render1x2result(
              0,
              '1',
              this.props.content.results
                ? this.props.content.results._12
                : null,
              this.props.content.isOdds && this.props.content.odds
                ? this.props.content.odds[0]
                : null,
              this.props.t('TARGET_RESULT_12_TITLE'),
            )}
            {this.render1x2result(
              1,
              '2',
              this.props.content.results
                ? this.props.content.results._12
                : null,
              this.props.content.isOdds && this.props.content.odds
                ? this.props.content.odds[1]
                : null,
            )}
          </>
        );
      }
    }
  }

  render1x2result(index, item, results, placeholder, title) {
    return (
      <ResultOption
        index={index}
        placeholder={placeholder}
        title={title}
        option={item}
        optionSelected={
          results &&
          Object.keys(results).length > 0 &&
          Object.keys(results)[0] === item
        }
        onOptionClick={this.onResult1x2Press.bind(this, item, results)}
        value={
          results &&
          Object.keys(results).length > 0 &&
          Object.keys(results)[0] === item
            ? results[Object.keys(results)[0]]
            : ''
        }
        onValueChanged={this.on1x2ResultValueChange.bind(this)}
        result
      />
    );
  }

  renderScoreResults() {
    if (
      this.props.content.score &&
      this.props.content.bet_result &&
      this.props.usageType === 'updateResults'
    ) {
      return (
        <ResultScore
          home={
            this.props.content.results &&
            this.props.content.results.result &&
            this.props.content.results.result.home
              ? this.props.content.results.result.home
              : ''
          }
          visitor={
            this.props.content.results &&
            this.props.content.results.result &&
            this.props.content.results.result.visitor
              ? this.props.content.results.result.visitor
              : ''
          }
          value={
            this.props.content.results &&
            this.props.content.results.result &&
            this.props.content.results.result.score
              ? this.props.content.results.result.score
              : ''
          }
          onChanged={this.onScoreResultChange.bind(this)}
        />
      );
    }
  }

  renderOptions() {
    if (this.props.content.type === 'choose' && this.props.content.options) {
      return this.props.content.options.map((option, index) =>
        this.renderOption(option, index),
      );
    }
  }

  renderOption(option, index) {
    if (this.props.usageType === 'updateResults') {
      return (
        <ResultOption
          key={index}
          index={index}
          placeholder={
            this.props.content.isOdds && this.props.content.odds
              ? this.props.content.odds[index]
              : null
          }
          title={index === 0 ? this.props.t('TARGET_RESULTS_TITLE') : null}
          option={option}
          optionSelected={
            this.props.content.results &&
            this.props.content.results.options &&
            this.props.content.results.options.hasOwnProperty(index)
          }
          onOptionClick={this.onResultOptionPress.bind(this, index)}
          value={
            this.props.content.results &&
            this.props.content.results.options &&
            this.props.content.results.options[index]
              ? this.props.content.results.options[index]
              : ''
          }
          onValueChanged={this.onResultValueChange.bind(this)}
          result
        />
      );
    } else if (this.props.content.isOdds) {
      return (
        <InputOddOption
          key={index}
          index={index}
          placeholder={
            this.props.t('TARGET_CHOOSE_OPTION_PLACEHOLDER_1') +
            (index + 1) +
            this.props.t('TARGET_CHOOSE_OPTION_PLACEHOLDER_2')
          }
          title={index === 0 ? this.props.t('TARGET_CHOOSE_OPTIONS_TITLE') : ''}
          onChange={this.onChangeOption.bind(this, index)}
          optionValue={this.props.content.options[index]}
          value={this.props.content.odds[index]}
          onValueChanged={this.onWinningValueChange.bind(this)}
        />
      );
    }
    return (
      <BasicInput
        key={index}
        placeholder={
          this.props.t('TARGET_CHOOSE_OPTION_PLACEHOLDER_1') +
          (index + 1) +
          this.props.t('TARGET_CHOOSE_OPTION_PLACEHOLDER_2')
        }
        title={index === 0 ? this.props.t('TARGET_CHOOSE_OPTIONS_TITLE') : ''}
        titleNoTopMargin
        onChange={this.onChangeOption.bind(this, index)}
        value={this.props.content.options[index]}
        marginBottom
      />
    );
  }

  renderAddRemoveOptionButtons() {
    if (
      this.props.content.type === 'choose' &&
      this.props.index > this.props.lockedUntil &&
      this.props.usageType !== 'updateResults'
    ) {
      return (
        <AddRemoveButtons
          title={this.props.t('TARGET_CHOOSE_ADD_BUTTON')}
          removable={
            this.props.content.options && this.props.content.options.length > 2
          }
          onAddPressed={this.onAddOptionPressed.bind(this)}
          onRemovePressed={this.onRemoveOptionPressed.bind(this)}
        />
      );
    }
  }

  renderAddRemoveLinkButtons() {
    return (
      <AddRemoveButtons
        title={this.props.t('TARGET_LINK_ADD_BUTTON')}
        onlyRemovableTitle={this.props.t('TARGET_LINK_REMOVE_BUTTON')}
        addable={!this.state.link2Open}
        removable={this.state.link1Open || this.state.link2Open}
        onAddPressed={this.onAddLinkPressed.bind(this)}
        onRemovePressed={this.onRemoveLinkPressed.bind(this)}
      />
    );
  }

  renderClosingTime() {
    if (this.props.usageType !== 'updateResults') {
      return (
        <div className={classes.closingTimeContainer}>
          <Title
            title={
              this.props.usageType === 'new'
                ? `${this.props.t('TARGET_CLOSING_TIME')} *`
                : this.props.t('TARGET_CLOSING_TIME')
            }
          />
          <DatePicker
            onChange={this.onClosingDateChanged.bind(this)}
            closingDate={this.props.content.deadline}
            placeholder={this.props.t('TARGET_CLOSING_TIME_PLACEHOLDER')}
          />
        </div>
      );
    }
  }

  renderCopyTargetButton() {
    if (this.props.usageType !== 'updateResults') {
      return (
        <button
          type="button"
          className={classes.iconButton}
          onClick={this.onCopyTargetPressed.bind(this)}
        >
          <MdContentCopy size={35} />
        </button>
      );
    }
    return null;
  }

  renderActionButtons() {
    return (
      <div className={classes.actionButtonsContainer}>
        {this.renderPreviousTargetButton()}
        {this.renderNextTargetButton()}
        {this.renderCopyTargetButton()}
        {this.renderDeleteTargetButton()}
      </div>
    );
  }

  renderPreviousTargetButton() {
    return (
      <button
        type="button"
        className={mapPropsToStyles(
          [classes.iconButton],
          {
            hideVisibility: this.props.index <= 0,
          },
          classes,
        )}
        onClick={this.onPreviousPressed.bind(this)}
      >
        <MdChevronLeft size={45} />
      </button>
    );
  }

  renderNextTargetButton() {
    return (
      <button
        type="button"
        className={mapPropsToStyles(
          [classes.iconButton, classes.marginSides],
          {
            hideVisibility: this.props.index >= this.props.targetCount - 1,
          },
          classes,
        )}
        onClick={this.onNextPressed.bind(this)}
      >
        <MdChevronRight size={45} />
      </button>
    );
  }

  renderDeleteTargetButton() {
    if (this.props.usageType !== 'updateResults') {
      return (
        <button
          type="button"
          className={mapPropsToStyles(
            [classes.iconButton],
            {
              hideVisibility: this.props.index <= this.props.lockedUntil,
            },
            classes,
          )}
          onClick={this.onRemoveTargetPressed.bind(this)}
        >
          <MdDeleteForever size={40} />
        </button>
      );
    }
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  renderLink(visible, placeholder, onChange, value) {
    if (visible) {
      return (
        <BasicInput
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          marginBottom
        />
      );
    }
  }

  renderLinks() {
    if (this.props.usageType === 'updateResults') {
      return (
        <>
          <Title title={this.props.t('TARGET_RESULTS_LINKS')} />
          {this.renderLink(
            this.props.content.links.result_first || this.state.link1Open,
            this.props.t('TARGET_RESULTS_LINK_PLACEHOLDER_1'),
            this.onChangeResultLink1.bind(this),
            this.props.content.links.result_first,
          )}
          {this.renderLink(
            this.props.content.links.result_second || this.state.link2Open,
            this.props.t('TARGET_RESULTS_LINK_PLACEHOLDER_2'),
            this.onChangeResultLink2.bind(this),
            this.props.content.links.result_second,
          )}
          {this.renderAddRemoveLinkButtons()}
        </>
      );
    } else {
      return (
        <>
          <Title title={this.props.t('TARGET_LINKS')} />
          {this.renderLink(
            this.props.content.links.first || this.state.link1Open,
            this.props.t('TARGET_LINK_PLACEHOLDER_1'),
            this.onChangeLink1.bind(this),
            this.props.content.links.first,
          )}
          {this.renderLink(
            this.props.content.links.second || this.state.link2Open,
            this.props.t('TARGET_LINK_PLACEHOLDER_2'),
            this.onChangeLink2.bind(this),
            this.props.content.links.second,
          )}
          {this.renderAddRemoveLinkButtons()}
        </>
      );
    }
  }

  renderTargetId() {
    if (
      this.props.usageType === 'updateResults' ||
      this.props.lockedUntil !== -1
    ) {
      return (
        <h1 className={classes.title}>
          {this.props.t('TARGET_ID_TITLE') +
            (this.props.index + 1) +
            (this.props.usageType === 'updateResults'
              ? ' ' + this.props.t('TARGET_RESULT_TITLE')
              : '')}
        </h1>
      );
    }
    let targetIdOptions = [...Array(this.props.targetCount).keys()];
    targetIdOptions = targetIdOptions.map((id) => id + 1);
    return (
      <ChooseBox
        selected={this.props.index}
        options={targetIdOptions}
        title={this.props.t('TARGET_ID_TITLE')}
        onSelected={this.onChangeTargetOrder.bind(this)}
      />
    );
  }

  getFullTargetNameString() {
    let name = this.props.content.name.trim();
    const home = this.props.content.home
      ? this.props.content.home.trim()
      : false;
    const visitor = this.props.content.visitor
      ? this.props.content.visitor.trim()
      : false;
    if (this.props.content.home || this.props.content.visitor) {
      if (name && name.slice(-1) !== ':') {
        name += ': ';
      }
      let homeFound = false;
      if (home) {
        name += home;
        homeFound = true;
      }
      if (visitor) {
        if (homeFound) {
          name += ' - ';
        }
        name += visitor;
      }
    }
    return name;
  }

  renderSelection() {
    if (this.props.usageType !== 'updateResults') {
      return (
        <>
          <Title title={this.props.t('TARGET_TYPE_TITLE')} />
          <div className={classes.selectionCheckboxContainer}>
            <CheckBox
              onClick={this.onCheckBoxPressed.bind(this, 'bet1x2')}
              title={this.props.t('TARGET_RESULT_1X2')}
              doubleRow
              disabled={
                this.props.index <= this.props.lockedUntil ||
                this.props.usageType === 'updateResults'
              }
            >
              {this.renderCheckboxIcon(this.props.content.bet1x2)}
            </CheckBox>
            <CheckBox
              onClick={this.onCheckBoxPressed.bind(this, 'bet12')}
              title={this.props.t('TARGET_RESULT_12')}
              doubleRow
              disabled={
                this.props.index <= this.props.lockedUntil ||
                this.props.usageType === 'updateResults'
              }
            >
              {this.renderCheckboxIcon(this.props.content.bet12)}
            </CheckBox>
            <CheckBox
              onClick={this.onCheckBoxPressed.bind(this, 'score')}
              title={this.props.t('TARGET_RESULT_SCORE')}
              doubleRow
              disabled={
                this.props.index <= this.props.lockedUntil ||
                this.props.usageType === 'updateResults'
              }
            >
              {this.renderCheckboxIcon(this.props.content.score)}
            </CheckBox>
            <CheckBox
              onClick={this.onCheckBoxPressed.bind(this, 'options')}
              title={this.props.t('TARGET_CHOOSE_OPTIONS')}
              doubleRow
              disabled={
                this.props.index <= this.props.lockedUntil ||
                this.props.usageType === 'updateResults'
              }
            >
              {this.renderCheckboxIcon(this.props.content.type === 'choose')}
            </CheckBox>
          </div>
        </>
      );
    }
  }

  renderCheckboxIcon(checked) {
    if (checked) {
      return <MdCheckBox size={30} style={{ marginRight: 10 }} />;
    }
    return <MdCheckBoxOutlineBlank size={30} style={{ marginRight: 10 }} />;
  }

  renderName() {
    if (this.props.usageType === 'updateResults') {
      return (
        <BasicInput
          title={
            this.props.usageType === 'new' ? `${this.props.t('TARGET_NAME')} *` : this.props.t('TARGET_NAME')
          }
          placeholder={this.props.t('TARGET_NAME_PLACEHOLDER')}
          titleNoTopMargin
          value={this.getFullTargetNameString()}
          disabled
          marginBottom
          multiline
        />
      );
    }
    return (
      <>
        <BasicInput
          title={
            this.props.usageType === 'new' ? `${this.props.t('TARGET_NAME')} *` : this.props.t('TARGET_NAME')
          }
          placeholder={this.props.t('TARGET_NAME_PLACEHOLDER')}
          titleNoTopMargin
          onChange={this.onChangeName.bind(this)}
          value={this.props.content.name}
          disabled={this.props.usageType === 'updateResults'}
        />
        <InputWithSelection
          title={this.props.t('TARGET_TEAM_HOME')}
          placeholder={this.props.t('TARGET_TEAM_HOME_PLACEHOLDER')}
          onChange={this.onChangeHomeTeam.bind(this)}
          value={this.props.content.home}
        />
        <InputWithSelection
          title={this.props.t('TARGET_TEAM_VISITOR')}
          placeholder={this.props.t('TARGET_TEAM_VISITOR_PLACEHOLDER')}
          onChange={this.onChangeVisitorTeam.bind(this)}
          value={this.props.content.visitor}
        />
      </>
    );
  }

  renderOpened() {
    if (this.props.content.open) {
      return (
        <>
          <div className={classes.titleRemoveContainer}>
            {this.renderTargetId()}
            {this.renderActionButtons()}
          </div>
          {this.renderName()}
          {this.renderClosingTime()}
          {this.renderSelection()}
          {this.render1x2Odds()}
          {this.renderScoreLimits()}
          {this.render1x2Results()}
          {this.renderScoreResults()}
          {this.renderOptions()}
          {this.renderAddRemoveOptionButtons()}
          {this.renderLinks()}
        </>
      );
    }
  }

  render() {
    return (
      <div
        className={[
          classes.container,
          this.props.content.open ? classes.containerOpen : '',
        ].join(' ')}
      >
        {this.renderOpened()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { lockedUntil, usageType } = state.tournament;
  return { lockedUntil, usageType };
};

export default connect(mapStateToProps, {
  updateTarget,
  removeTarget,
  expandTargets,
  changeTargetOrder,
  copyToNewTarget,
})(withTranslation()(Target));
