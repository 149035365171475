import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  resetAll,
  fetchTournament,
  updateGame,
  shortcutQuery,
  startCreating,
  sendEmail,
  importGame,
  importPreTemplate,
} from '../actions';
import Spinner from '../components/common/Spinner';
import BasicButton from '../components/BasicButton';
import BasicInput from '../components/BasicInput';
import Headline from '../components/Headline';
import Link from '../components/Link';
import Guide from '../components/Guide';
import Terms from '../components/Terms';
import Tournament from './Tournament';
import UserControl from './UserControl';
import MobileMenubar from './MobileMenubar';
import Menubar from './Menubar';
import { MAIN_PUBLIC_LINK } from '../Constants';
import classes from './OrganizerMain.module.css';

class OrganizerMain extends React.Component {
  constructor(props) {
    super(props);
    this.props.shortcutQuery();
    this.containerRef = React.createRef();

    this.state = {
      email: '',
      termsVisible: false,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.usageType !== newProps.usageType) {
      this.containerRef.current.scrollIntoView({
        block: 'start',
      });
    }
    this.setState({ termsVisible: false });
  }

  onCancel() {
    this.props.resetAll();
  }

  onShowTerms() {
    this.setState({ termsVisible: !this.state.termsVisible });
  }

  renderTournament() {
    if (
      this.props.usageType === 'new' ||
      (this.props.usageType === 'modify' && !this.props.fetchVisible) ||
      (this.props.usageType === 'updateResults' && !this.props.fetchVisible)
    ) {
      return <Tournament />;
    }
  }

  renderCreateButton(buttonText, thisOrNone, onClick, disabled) {
    if (this.props.usageType === 'start') {
      return (
        <div className={classes.centerHelpContainer}>
          <BasicButton onClick={this.props.startCreating.bind(this)}>
            {this.props.t('MAIN_CREATE_BUTTON')}
          </BasicButton>
          <h1 className={`${classes.title} ${classes.orTitle}`}>
            {this.props.t('MAIN_OR_OPTION')}
          </h1>
          <BasicButton onClick={this.props.importGame.bind(this)}>
            {this.props.t('IMPORT_GAME')}
          </BasicButton>
        </div>
      );
    }
  }

  renderFastPurposeText() {
    if (this.props.usageType === 'start') {
      return (
        <div className={classes.centerHelpContainer}>
          <h1 className={classes.title}>
            {this.props.t('MAIN_USAGE_REMINDER')}
          </h1>
        </div>
      );
    }
  }

  renderFetch() {
    if (this.props.fetchVisible) {
      return (
        <div className={classes.centerHelpContainer}>
          <h1 className={classes.title}>
            {this.props.t('LOGIN_ORGANIZER_TITLE')}
          </h1>
          <BasicInput
            placeholder={this.props.t('MAIN_FETCH_PLACEHOLDER')}
            onChange={(value) =>
              this.props.updateGame({ ...this.props.game, id: value })
            }
            value={this.props.game.id}
          />
          <BasicInput
            placeholder={this.props.t('MAIN_FETCH_PASSWORD_PLACEHOLDER')}
            onChange={(value) =>
              this.props.updateGame({ ...this.props.game, password: value })
            }
            value={this.props.game.password}
            password
          />
          <BasicButton
            onClick={this.props.fetchTournament.bind(
              this,
              this.props.game.id,
              this.props.game.password,
            )}
          >
            {this.props.t('MAIN_FETCH_BUTTON')}
          </BasicButton>
        </div>
      );
    }
  }

  renderCancel() {
    if (this.props.usageType === 'new') {
      return (
        <BasicButton onClick={this.onCancel.bind(this)}>
          {this.props.t('MAIN_CANCEL')}
        </BasicButton>
      );
    }
  }

  renderIdAndLink() {
    if (this.props.game.id) {
      return (
        <>
          <Headline
            title={this.props.t('MAIN_PUBLISHED_CODE_TITLE')}
            headline={this.props.game.id}
          />
          <Link
            title={this.props.t('MAIN_PUBLISHED_SHARE_URL')}
            headline={MAIN_PUBLIC_LINK + this.props.game.id}
            buttonText={this.props.t('MAIN_PUBLISHED_COPY_LINK')}
          />
        </>
      );
    }
  }

  renderUserControl() {
    if (this.props.usageType === 'userControl' && !this.props.fetchVisible) {
      return <UserControl />;
    }
  }

  renderShareInfo() {
    if (this.props.usageType === 'shareInfo' && !this.props.fetchVisible) {
      return (
        <>
          {this.renderIdAndLink()}
          <BasicInput
            title={this.props.t('EMAIL_TITLE')}
            placeholder={this.props.t('EMAIL_PLACEHOLDER')}
            onChange={(value) => this.setState({ email: value })}
            value={this.state.email}
          />
          <BasicButton
            onClick={this.props.sendEmail.bind(this, this.state.email)}
            marginTopButton
          >
            {this.props.t('EMAIL_SEND')}
          </BasicButton>
        </>
      );
    }
  }

  renderGuide() {
    return <Guide />;
  }

  renderTerms() {
    if (this.state.termsVisible) {
      return <Terms />;
    }
  }

  renderContent() {
    if (this.props.loading) {
      return <Spinner />;
    }
    return (
      <>
        {this.renderFastPurposeText()}
        {this.renderCreateButton()}
        {this.renderFetch()}
        {this.renderUserControl()}
        {this.renderTournament()}
        {this.renderCancel()}
        {this.renderShareInfo()}
      </>
    );
  }

  render() {
    return (
      <div ref={this.containerRef}>
        <Menubar />
        <div className={classes.container}>
          {this.renderContent()}
          {this.renderGuide()}
          {this.renderTerms()}
          <div className={classes.footerContainer}>
            <footer className={classes.footer}>
              <p className={classes.footerItem}>
                {' '}
                {this.props.t('COPYRIGHT_1') +
                  String.fromCharCode(169) +
                  this.props.t('COPYRIGHT_2')}
              </p>
              <p className={`${classes.footerItem} ${classes.footerMiddle}`}>
                {' '}
                {this.props.t('CONTACT_EMAIL')}
              </p>
              <p
                className={`${classes.footerItem} ${classes.footerPointer}`}
                onClick={this.onShowTerms.bind(this)}
              >
                {' '}
                {this.props.t('TERMS_LINK')}
              </p>
            </footer>
          </div>
        </div>
        <MobileMenubar />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { usageType, game, fetchVisible, loading } = state.tournament;
  return { usageType, game, fetchVisible, loading };
};

export default connect(mapStateToProps, {
  resetAll,
  fetchTournament,
  updateGame,
  shortcutQuery,
  startCreating,
  sendEmail,
  importGame,
  importPreTemplate,
})(withTranslation()(OrganizerMain));
