import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import Input from './common/Input';
import classes from './InputWithSelection.module.css';

const InputOddOption = ({
  placeholder,
  title,
  onChange = () => {},
  value,
  disabled,
}) => {
  const [selectionOpen, toggleSelection] = useState(false);
  const { game } = useSelector((state) => state.tournament);

  const getTeams = () => {
    const teams = new Set();
    if (game.targets) {
      const targetArray = Object.values(game.targets);
      for (let i = 0; i < targetArray.length; i += 1) {
        if (targetArray[i].home) {
          teams.add(targetArray[i].home);
        }
        if (targetArray[i].visitor) {
          teams.add(targetArray[i].visitor);
        }
      }
    }
    return teams;
  };

  const onSelect = (item) => {
    onChange(item);
    toggleSelection(!selectionOpen);
  };

  const renderOption = (item) => {
    return (
      <button
        key={item}
        type="button"
        className={classes.OptionButton}
        onClick={() => onSelect(item)}
      >
        {item}
      </button>
    );
  };

  const renderSelectionMenu = () => {
    if (selectionOpen) {
      const teams = Array.from(getTeams()).sort();
      return (
        <div className={classes.SelectionMenu}>
          {teams.map((item) => renderOption(item))}
        </div>
      );
    }
    return null;
  };

  const renderSelectionButton = () => {
    if (getTeams().size > 0) {
      return (
        <button
          type="button"
          className={classes.selectionButton}
          onClick={() => toggleSelection(!selectionOpen)}
        >
          <FaBars size={25} />
        </button>
      );
    }
    return null;
  };

  return (
    <>
      <h1 className={classes.title}>{title}</h1>
      <div className={classes.inputWithSelection}>
        <Input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        {renderSelectionButton()}
      </div>
      {renderSelectionMenu()}
    </>
  );
};

export default InputOddOption;
