import React from 'react';
import { MdDeleteForever } from 'react-icons/md';
import mapPropsToStyles from './common/mapPropsToStyles';
import classes from './User.module.css';

const User = ({ title, user, userSelected, onUserClick, onUserDelete }) => {
  return (
    <>
      <h1 className={classes.title}>{title}</h1>
      <div className={classes.container}>
        <button
          type="button"
          className={mapPropsToStyles(
            [classes.selectUserButton],
            {
              dark: userSelected,
            },
            classes,
          )}
          onClick={onUserClick}
        >
          {user}
        </button>
        <button
          type="button"
          className={classes.removeUserButton}
          onClick={onUserDelete}
        >
          <MdDeleteForever size={35} />
        </button>
      </div>
    </>
  );
};

export default User;
