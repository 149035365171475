import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  updateGame,
  setUserPassword,
  deleteUser,
  selectUser,
} from '../actions';
import BasicButton from '../components/BasicButton';
import BasicInput from '../components/BasicInput';
import User from '../components/User';
import classes from './UserControl.module.css';

class UserControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: '',
    };
  }

  onSetAllowSignIn(allowSignIn) {
    this.props.updateGame({ ...this.props.game, allowSignIn });
  }

  renderUserOpenInfo(user) {
    if (user.name === this.props.selectedUser) {
      return (
        <>
          <BasicInput
            placeholder={this.props.t('USER_NEW_PASSWORD_PLACEHOLDER')}
            value={this.state.newPassword}
            onChange={(value) => this.setState({ newPassword: value })}
          />
          <BasicButton
            onClick={this.props.setUserPassword.bind(
              this,
              user.name,
              this.state.newPassword,
            )}
            disabled={this.state.newPassword.length === 0}
            selected={this.state.newPassword.length === 0}
            marginTopButton
          >
            {this.props.t('USER_NEW_PASSWORD_BUTTON')}
          </BasicButton>
        </>
      );
    }
  }

  renderUsers() {
    if (this.props.participants) {
      return this.props.participants.map((user, index) =>
        this.renderUser(user, index),
      );
    }
  }

  renderUser(user, index) {
    return (
      <div key={index}>
        <User
          title={index === 0 ? this.props.t('USER_TITLE') : false}
          user={user.name}
          onUserClick={this.props.selectUser.bind(this, user.name)}
          onUserDelete={this.props.deleteUser.bind(this, user.name)}
          userSelected={this.props.selectedUser === user.name}
        />
        {this.renderUserOpenInfo(user)}
      </div>
    );
  }

  render() {
    return (
      <div className={classes.container}>
        <BasicInput
          title={this.props.t('NEW_TITLE')}
          value={this.props.game.name}
          disabled={true}
        />
        <div className={classes.allowButtonsContainer}>
          <BasicButton
            onClick={this.onSetAllowSignIn.bind(this, true)}
            doubleRowLeft
            disabled={this.props.game.allowSignIn}
            title={this.props.t('USER_ALLOW_SIGN_TITLE')}
            selected={this.props.game.allowSignIn}
          >
            {this.props.t('USER_ALLOW_SIGN_YES')}
          </BasicButton>
          <BasicButton
            onClick={this.onSetAllowSignIn.bind(this, false)}
            doubleRowRight
            disabled={!this.props.game.allowSignIn}
            title={' '}
            selected={!this.props.game.allowSignIn}
          >
            {this.props.t('USER_ALLOW_SIGN_NO')}
          </BasicButton>
        </div>
        {this.renderUsers()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { game, participants, selectedUser } = state.tournament;
  return { game, participants, selectedUser };
};

export default connect(mapStateToProps, {
  updateGame,
  setUserPassword,
  deleteUser,
  selectUser,
})(withTranslation()(UserControl));
