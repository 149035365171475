import React from 'react';
import mapPropsToStyles from './common/mapPropsToStyles';
import classes from './CheckBox.module.css';

const CheckBox = ({
  onClick,
  children,
  title,
  doubleRow,
  disabled = false,
  noBottomMargin = false,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={mapPropsToStyles(
        [classes.CheckBox],
        {
          doubleRow,
          noBottomMargin,
        },
        classes,
      )}
    >
      {children}
      {title}
    </button>
  );
};

export default CheckBox;
