import React from 'react';
import { useTranslation } from 'react-i18next';
import Input from './common/Input';
import mapPropsToStyles from './common/mapPropsToStyles';
import classes from './ResultOption.module.css';

const ResultOption = ({
  index,
  title,
  placeholder,
  option,
  optionSelected,
  onOptionClick,
  value,
  onValueChanged,
  disabled = false,
  result = false,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className={classes.title}>{title}</h1>
      <div className={classes.container}>
        <button
          type="button"
          className={mapPropsToStyles(
            [classes.button],
            {
              dark: disabled ? false : optionSelected,
            },
            classes,
          )}
          onClick={onOptionClick}
          disabled={disabled}
        >
          {option}
        </button>
        <h1 className={classes.winTitle}>
          {result ? t('TARGET_RESULTS_WIN') : t('TARGET_RESULTS_ODD')}
        </h1>
        <Input
          decimal
          resultOption
          placeholder={placeholder}
          value={value}
          onChange={(newVal) => onValueChanged(newVal, index)}
          disabled={!optionSelected}
        />
      </div>
    </>
  );
};

export default ResultOption;
