import React, { useState } from 'react';
import { FaCaretDown } from 'react-icons/fa';
import classes from './ChooseBox.module.css';

const ChooseBox = ({ selected, options, title, onSelected }) => {
  const [optionsOpen, setOptionsOpen] = useState(false);

  const onOpenOptions = () => {
    setOptionsOpen(!optionsOpen);
  };

  const onOptionSelected = (option) => {
    onSelected(selected, option);
    setOptionsOpen(!optionsOpen);
  };

  const renderChooseBoxOptions = () => {
    if (optionsOpen) {
      return (
        <div className={classes.ChooseBoxOptions}>
          {Object.keys(options).map((option) => {
            if (option !== selected) {
              return (
                <div
                  key={option}
                  className={classes.ChooseBoxOption}
                  onClick={() => onOptionSelected(option)}
                  aria-hidden="true"
                >
                  {options[option]}
                </div>
              );
            }
            return null;
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <div className={classes.Container}>
      {title}
      <div
        className={classes.ChooseBox}
        onClick={onOpenOptions}
        aria-hidden="true"
      >
        <div className={classes.ChooseBoxSelected}>
          {options[selected]}
          <FaCaretDown />
        </div>
        {renderChooseBoxOptions()}
      </div>
    </div>
  );
};

export default ChooseBox;
