import queryString from 'query-string';
import isURL from 'validator/lib/isURL';
import i18next from '../i18n';
import * as FetchHandler from './FetchHandler';
import TEMPLATE from '../assets/FriendlyBet_current_template.json';
import {
  NEW_TARGET_CREATED,
  TARGET_REMOVED,
  TARGET_CHANGED,
  INVALID_TOURNAMENT,
  CODE_FETCHED,
  RESET_REDUCERS,
  RETYPED_PASSWORD_CHANGED,
  TOURNAMENT_PUBLISHED,
  GAME_CHANGED,
  USER_DELETED,
  USER_PASSWORD_CHANGED,
  USER_SELECTED,
  START_CREATING,
  NAVIGATED_TO,
  COPIED_GAME,
  LOADING,
  DISCARD_CHANGES,
  TARGET_EXPANDED,
  SHOW_ALL_TARGETS,
  COPIED_NEW_TARGET,
} from './types';
import { API_URL } from '../Constants';

export const resetAll = () => {
  return (dispatch, getState) => {
    dispatch({ type: RESET_REDUCERS, payload: true });
    const newurl = window.location.href.split('?')[0];
    window.history.pushState({ path: newurl }, '', newurl);
    shortcutQueryExec(dispatch, getState, 'start');
  };
};

export const createNewTarget = () => {
  return {
    type: NEW_TARGET_CREATED,
    payload: true,
  };
};

export const removeTarget = (index) => {
  return (dispatch, getState) => {
    const { game } = getState().tournament;
    if (
      window.confirm(
        i18next.t('CONFIRM_DELETE_TARGET') +
          (index + 1) +
          ': ' +
          game.targets[index].name,
      )
    ) {
      dispatch({ type: TARGET_REMOVED, payload: index });
    }
  };
};

export const updateTarget = (target) => {
  return {
    type: TARGET_CHANGED,
    payload: target,
  };
};

export const updateGame = (game) => {
  return {
    type: GAME_CHANGED,
    payload: game,
  };
};

export const publish = () => {
  return (dispatch, getState) => {
    validate(dispatch, getState, publishExec, () => {
      console.log('Publish failed! Correct the failures and try again');
    });
  };
};

const validate = (
  dispatch,
  getState,
  callback,
  errorCallback,
  externalGame,
) => {
  dispatch({ type: LOADING, payload: true });
  const { retypedPassword, usageType } = getState().tournament;
  let invalidTournament = {
    name: false,
    link: false,
    target: false,
    targetName: false,
    targetFirst: false,
    targetSecond: false,
    targetDeadline: false,
    targetBetResults: false,
    targetOptions: false,
    validTournament: false,
  };
  const game = externalGame ? externalGame : getState().tournament.game;
  let valid = true;
  if (!game.name) {
    valid = false;
    invalidTournament.name = true;
    dispatch({ type: INVALID_TOURNAMENT, payload: invalidTournament });
  }
  if (game.link && !validURL(game.link)) {
    valid = false;
    invalidTournament.link = true;
    dispatch({ type: INVALID_TOURNAMENT, payload: invalidTournament });
  }
  if (
    usageType === 'new' &&
    (game.password.length < 1 || game.password !== retypedPassword)
  ) {
    valid = false;
    invalidTournament.password = true;
    dispatch({ type: INVALID_TOURNAMENT, payload: invalidTournament });
  }
  let targetsCopy = JSON.parse(JSON.stringify(game.targets));
  const targetsWithIds = targetsCopy.map((target, index) => {
    return { ...target, id: index + 1 };
  });
  let cleanedTargets = checkIsValidAndCleanTargets(
    targetsWithIds,
    dispatch,
    invalidTournament,
    game,
  );

  if (cleanedTargets && valid) {
    const arrayToObject = (array) =>
      array.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
      }, {});
    const targetsObject = arrayToObject(cleanedTargets);

    const tournamentJson = { ...game, targets: targetsObject };

    callback(dispatch, getState, tournamentJson, game);
  } else {
    dispatch({ type: LOADING, payload: false });
    errorCallback(dispatch, getState);
  }
};

const publishExec = (dispatch, getState, tournament, origGame) => {
  const tournamentJson = JSON.stringify(tournament);
  const url = origGame.id.trim()
    ? API_URL + '/game/' + origGame.id + '?password=' + origGame.password
    : API_URL + '/game';
  const command = origGame.id ? 'PUT' : 'POST';
  FetchHandler.fetchAwsWithFailure(
    url,
    dispatch,
    (game) => {
      const params = queryString.parse(window.location.search);
      if (!origGame.id && params.id !== game.id) {
        params.id = game.id;
        const newurl =
          window.location.href.split('?')[0] +
          '?' +
          queryString.stringify(params);
        window.history.pushState({ path: newurl }, '', newurl);
        localStorage.setItem(game.id, game.password);
      }
      dispatch({
        type: TOURNAMENT_PUBLISHED,
        payload: {
          ...game,
          targets: addOpenInfoForTargets(Object.values(game.targets)),
        },
      });
      dispatch({ type: LOADING, payload: false });
      if (!origGame.id) {
        navigateToExec(dispatch, 'shareInfo');
      }
    },
    (failure) => {
      console.log(failure);
      dispatch({ type: LOADING, payload: false });
      alert(i18next.t('ERROR_PUBLISH'));
    },
    3,
    command,
    tournamentJson,
  );
  //TODO: jos ei backend tykännyt niin joku virhe käsittely
};

const checkIsValidAndCleanTargets = (
  targets,
  dispatch,
  invalidTournament,
  game,
) => {
  let cleanedTargets = targets.map((target, index) =>
    checkIsValidAndCleanTarget(
      target,
      index,
      invalidTournament,
      game,
      dispatch,
    ),
  );
  if (cleanedTargets.length < 1) {
    invalidTournament.target = true;
    dispatch({ type: INVALID_TOURNAMENT, payload: invalidTournament });
    return false;
  }
  let valid = true;
  for (let i = 0; i < cleanedTargets.length; i++) {
    if (!cleanedTargets[i]) {
      valid = false;
    }
  }
  return valid ? cleanedTargets : false;
};

const checkIsValidAndCleanTarget = (
  target,
  index,
  invalidTournament,
  game,
  dispatch,
) => {
  let valid = true;
  if (!target.name && !target.home && !target.visitor) {
    valid = false;
    invalidTournament.targetName = true;
    dispatch({ type: INVALID_TOURNAMENT, payload: invalidTournament });
  }
  if (!target.deadline) {
    if (
      game.deadlinesOptional ||
      window.confirm(i18next.t('CONFIRM_PUBLISH_WITHOUT_DEADLINE'))
    ) {
      game.deadlinesOptional = true;
    } else {
      valid = false;
      invalidTournament.targetDeadline = true;
      dispatch({ type: INVALID_TOURNAMENT, payload: invalidTournament });
    }
  }
  if (!target.links.first) {
    delete target.links.first;
  } else if (!validURL(target.links.first)) {
    valid = false;
    invalidTournament.targetFirst = true;
    dispatch({ type: INVALID_TOURNAMENT, payload: invalidTournament });
  }
  if (!target.links.second) {
    delete target.links.second;
  } else if (!validURL(target.links.second)) {
    valid = false;
    invalidTournament.targetSecond = true;
    dispatch({ type: INVALID_TOURNAMENT, payload: invalidTournament });
  }
  if (!target.links.result_first) {
    delete target.links.result_first;
  } else if (!validURL(target.links.result_first)) {
    valid = false;
    invalidTournament.resultFirst = true;
    dispatch({ type: INVALID_TOURNAMENT, payload: invalidTournament });
  }
  if (!target.links.result_second) {
    delete target.links.result_second;
  } else if (!validURL(target.links.result_second)) {
    valid = false;
    invalidTournament.resultSecond = true;
    dispatch({ type: INVALID_TOURNAMENT, payload: invalidTournament });
  }
  if (target.type === 'choose') {
    delete target.bet_results;
    for (let i = target.options.length - 1; i >= 0; i--) {
      if (!target.options[i]) {
        target.options.splice(i, 1);
      }
    }
    if (!target.options || target.options.length < 2) {
      valid = false;
      invalidTournament.targetOptions = true;
      dispatch({ type: INVALID_TOURNAMENT, payload: invalidTournament });
    }
  } else if (target.type === 'result') {
    delete target.options;
    if (!target.score) {
      delete target.bet_result;
    } else if (
      (target.bet_result.from !== 0 && !target.bet_result.from) ||
      !target.bet_result.to
    ) {
      valid = false;
      invalidTournament.targetBetResults = true;
      dispatch({ type: INVALID_TOURNAMENT, payload: invalidTournament });
    }
  }
  if (target.results) {
    if (target.results.result) {
      if (
        !target.results.result.home ||
        !target.results.result.visitor ||
        !target.results.result.score ||
        isNaN(Number(target.results.result.home)) ||
        isNaN(Number(target.results.result.visitor)) ||
        isNaN(Number(target.results.result.score))
      ) {
        valid = false;
        invalidTournament.resultScore = true;
        dispatch({ type: INVALID_TOURNAMENT, payload: invalidTournament });
      }
    }
    if (target.results._1x2) {
      if (
        Object.keys(target.results._1x2).length !== 1 ||
        isNaN(Number(target.results._1x2[Object.keys(target.results._1x2)[0]]))
      ) {
        valid = false;
        invalidTournament.result1x2 = true;
        dispatch({ type: INVALID_TOURNAMENT, payload: invalidTournament });
      }
    }
    if (target.results._12) {
      if (
        Object.keys(target.results._12).length !== 1 ||
        isNaN(Number(target.results._12[Object.keys(target.results._12)[0]]))
      ) {
        valid = false;
        invalidTournament.result12 = true;
        dispatch({ type: INVALID_TOURNAMENT, payload: invalidTournament });
      }
    }
    if (target.results.options) {
      if (Object.keys(target.results.options).length < 1) {
        delete target.results.options;
      } else {
        for (let i = 0; i < Object.keys(target.results.options).length; i++) {
          if (
            isNaN(
              Number(
                target.results.options[Object.keys(target.results.options)[i]],
              ),
            )
          ) {
            valid = false;
            invalidTournament.resultOptions = true;
            dispatch({ type: INVALID_TOURNAMENT, payload: invalidTournament });
          }
        }
      }
    }

    if (target.type === 'choose') {
      delete target.results.bet_result;
    } else if (target.type === 'result') {
      delete target.results.options;
    }
  }

  delete target.open;

  return valid ? target : false;
};

const validURL = (str) => {
  return isURL(str);
};

export const fetchTournament = (id, password) => {
  return (dispatch, getState) => {
    dispatch({ type: LOADING, payload: true });
    fetchTournamentExec(id, password, dispatch, getState);
  };
};

const fetchTournamentExec = (id, password, dispatch, getState, usageType) => {
  FetchHandler.fetchAwsWithFailure(
    API_URL + '/game/' + id.trim() + '?password=' + password,
    dispatch,
    (fetchedTournament) => {
      const params = queryString.parse(window.location.search);
      if (params.id !== id) {
        params.id = id;
        const newurl =
          window.location.href.split('?')[0] +
          '?' +
          queryString.stringify(params);
        window.history.pushState({ path: newurl }, '', newurl);
      }
      localStorage.setItem(id, password);
      fetchUsers(fetchedTournament, dispatch, getState, usageType);
    },
    (failure) => {
      console.log(failure);
      dispatch({ type: LOADING, payload: false });
      alert(
        i18next.t('ERROR_TOURNAMENT_1') + id + i18next.t('ERROR_TOURNAMENT_2'),
      );
    },
    3,
  );
};

const fetchUsers = (fetchedTournament, dispatch, getState, newUsageType) => {
  const { usageType } = getState().tournament;
  FetchHandler.fetchAwsWithFailure(
    API_URL + '/game/' + fetchedTournament.id + '/bets',
    dispatch,
    (participants) => {
      let finalUsageType = newUsageType
        ? newUsageType
        : usageType === 'login'
        ? participants.length > 0
          ? 'updateResults'
          : 'modify'
        : usageType;
      dispatch({
        type: CODE_FETCHED,
        payload: {
          game: {
            ...fetchedTournament,
            targets: addOpenInfoForTargets(
              Object.values(fetchedTournament.targets),
              finalUsageType,
            ),
          },
          participants,
          usageType: finalUsageType,
        },
      });
      dispatch({ type: LOADING, payload: false });
      navigateToExec(dispatch, finalUsageType);
    },
    (failure) => {
      console.log(failure);
      dispatch({ type: LOADING, payload: false });
      alert(
        i18next.t('ERROR_USERS_1') +
          fetchedTournament.id +
          i18next.t('ERROR_USERS_2'),
      );
    },
    3,
  );
};

const addOpenInfoForTargets = (targets, usageType) => {
  for (let i = 0; i < targets.length; i++) {
    targets[i].open = false;
  }
  return targets;
};

export const changeRetypedPassword = (value) => {
  return {
    type: RETYPED_PASSWORD_CHANGED,
    payload: value,
  };
};

export const setUserPassword = (userName, password) => {
  return (dispatch, getState) => {
    const { game } = getState().tournament;
    FetchHandler.fetchAwsWithFailure(
      API_URL +
        '/game/' +
        game.id +
        '/bets/' +
        userName +
        '/password?password=' +
        game.password,
      dispatch,
      (response) => {
        dispatch({ type: USER_PASSWORD_CHANGED, payload: userName });
      },
      (failure) => {
        console.log(failure);
        alert(i18next.t('ERROR_NEW_USER_PASSWORD') + userName);
      },
      3,
      'put',
      password,
    );
  };
};

export const deleteUser = (userName) => {
  return (dispatch, getState) => {
    const { game } = getState().tournament;
    if (window.confirm(i18next.t('CONFIRM_DELETE_USER') + userName)) {
      FetchHandler.fetchAwsWithFailure(
        API_URL +
          '/game/' +
          game.id +
          '/bets/' +
          userName +
          '?password=' +
          game.password,
        dispatch,
        (response) => {
          dispatch({ type: USER_DELETED, payload: userName });
        },
        (failure) => {
          console.log(failure);
          alert(i18next.t('ERROR_DELETE_USER') + userName);
        },
        3,
        'delete',
        {},
      );
    }
  };
};

export const selectUser = (userName) => {
  return {
    type: USER_SELECTED,
    payload: userName,
  };
};

export const shortcutQuery = () => {
  return (dispatch, getState) => {
    shortcutQueryExec(dispatch, getState);
  };
};

const shortcutQueryExec = (dispatch, getState, targetType) => {
  const params = queryString.parse(window.location.search);
  if (params.id && !params.password) {
    const password = localStorage.getItem(params.id);
    if (password) {
      params.password = password;
    } else {
      navigateToExec(dispatch, params.type);
      dispatch({ type: LOADING, payload: false });
    }
  }
  if (params.id && params.password) {
    let type = targetType
      ? targetType
      : params.type
      ? params.type
      : 'updateResults';
    fetchTournamentExec(params.id, params.password, dispatch, getState, type);
  } else if (params.type) {
    navigateToExec(dispatch, params.type);
    dispatch({ type: LOADING, payload: false });
  } else if (params.id) {
    const { game } = getState().tournament;
    game.id = params.id;
    dispatch({ type: GAME_CHANGED, payload: game });
    navigateToExec(dispatch, 'login');
    dispatch({ type: LOADING, payload: false });
  } else {
    dispatch({ type: LOADING, payload: false });
  }
};

export const startCreating = () => {
  return {
    type: START_CREATING,
    payload: true,
  };
};

export const sendEmail = (email) => {
  return (dispatch, getState) => {
    const { game } = getState().tournament;
    dispatch({ type: LOADING, payload: true });
    FetchHandler.fetchAwsWithFailure(
      API_URL + '/game/' + game.id + '/email?password=' + game.password,
      dispatch,
      (response) => {
        dispatch({ type: LOADING, payload: false });
        alert(
          i18next.t('ALERT_EMAIL_SENT') +
            email +
            i18next.t('ALERT_EMAIL_SENT_JUNK'),
        );
      },
      (failure) => {
        console.log(failure);
        dispatch({ type: LOADING, payload: false });
        alert(i18next.t('ERROR_EMAIL_SENT') + email);
      },
      3,
      'post',
      email,
    );
  };
};

export const navigateTo = (newUsageType) => {
  return (dispatch, getState) => {
    const { game, unpublishedChanges, usageType } = getState().tournament;
    if (!unpublishedChanges) {
      if (newUsageType === 'participantView') {
        if (game.id) {
          const htmlpage =
            usageType === 'updateResults' ? '/scoreboard.html' : '';
          window.open(
            'https://g.friendlybet.org' + htmlpage + '?id=' + game.id,
          );
        }
      } else if (game.id && newUsageType === 'userControl') {
        fetchTournamentExec(
          game.id,
          game.password,
          dispatch,
          getState,
          newUsageType,
        );
      } else {
        navigateToExec(dispatch, newUsageType);
      }
    } else if (window.confirm(i18next.t('CONFIRM_DISCARD_CHANGES'))) {
      if (newUsageType === 'participantView') {
        if (game.id) {
          const htmlpage =
            usageType === 'updateResults' ? '/scoreboard.html' : '';
          window.open(
            'https://g.friendlybet.org' + htmlpage + '?id=' + game.id,
          );
        }
      } else if (game.id) {
        fetchTournamentExec(
          game.id,
          game.password,
          dispatch,
          getState,
          newUsageType,
        );
      } else {
        dispatch({ type: DISCARD_CHANGES, payload: true });
        navigateToExec(dispatch, newUsageType);
      }
    }
  };
};

const navigateToExec = (dispatch, usageType) => {
  if (
    usageType === 'modify' ||
    usageType === 'updateResults' ||
    usageType === 'userControl' ||
    usageType === 'shareInfo'
  ) {
    const params = queryString.parse(window.location.search);
    if (params.type !== usageType) {
      params.type = usageType;
      const newurl =
        window.location.href.split('?')[0] +
        '?' +
        queryString.stringify(params);
      window.history.pushState({ path: newurl }, '', newurl);
    }
  }
  dispatch({ type: NAVIGATED_TO, payload: usageType });
};

export const deleteGame = () => {
  return (dispatch, getState) => {
    const { game } = getState().tournament;
    dispatch({ type: LOADING, payload: true });
    if (window.confirm(i18next.t('CONFIRM_DELETE_GAME') + game.id)) {
      FetchHandler.fetchAwsWithFailure(
        API_URL + '/game/' + game.id + '?password=' + game.password,
        dispatch,
        (response) => {
          console.log('Deleted game: ' + game.id);
          dispatch({ type: RESET_REDUCERS, payload: true });
          dispatch({ type: LOADING, payload: false });
        },
        (failure) => {
          console.log(failure);
          dispatch({ type: LOADING, payload: false });
          alert(i18next.t('ERROR_DELETE_GAME') + game.id);
        },
        3,
        'delete',
        {},
      );
    } else {
      dispatch({ type: LOADING, payload: false });
    }
  };
};

export const startFromCopy = () => {
  return (dispatch, getState) => {
    const { game } = getState().tournament;
    let newGame = JSON.parse(JSON.stringify(game));
    newGame.id = '';
    newGame.password = '';
    newGame.allowSignIn = true;
    for (let i = 0; i < newGame.targets.length; i++) {
      delete newGame.targets[i].results;
      newGame.targets[i].open = true;
    }
    dispatch({ type: COPIED_GAME, payload: newGame });
  };
};

export const expandTargets = (action, targetIndex, closeOthers) => {
  return (dispatch, getState) => {
    const { game } = getState().tournament;
    if (game.targets.length >= targetIndex + 1) {
      const thisTarget = game.targets[targetIndex];
      const more = action === 'more';
      if (thisTarget.open === more) {
        for (let i = 0; i < game.targets.length; i++) {
          game.targets[i].open = more;
        }
      } else {
        thisTarget.open = more;
        if (closeOthers === true) {
          for (let i = 0; i < game.targets.length; i++) {
            if (i !== targetIndex) {
              game.targets[i].open = false;
            }
          }
        }
      }
      dispatch({ type: TARGET_EXPANDED, payload: game });
    }
  };
};

export const importGame = () => {
  return (dispatch, getState) => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', '.json');
    fileSelector.addEventListener('change', (event) => {
      event.stopPropagation();
      event.preventDefault();
      var file = event.target.files[0];
      let reader = new FileReader();
      reader.addEventListener('load', () => {
        let exportedGameTemplate = JSON.parse(reader.result);
        let exportedGame = {
          ...exportedGameTemplate,
          id: '',
          password: '',
          allowSignIn: true,
          targets: addOpenInfoForTargets(
            Object.values(exportedGameTemplate.targets),
            'new',
          ),
        };
        validate(
          dispatch,
          getState,
          () => {
            dispatch({ type: LOADING, payload: false });
            dispatch({ type: COPIED_GAME, payload: exportedGame });
          },
          () => {
            dispatch({ type: LOADING, payload: false });
            alert(i18next.t('IMPORT_ERROR'));
          },
          JSON.parse(JSON.stringify(exportedGame)),
        );
      });
      reader.addEventListener('error', () => {
        dispatch({ type: LOADING, payload: false });
        alert(i18next.t('IMPORT_ERROR'));
      });
      reader.readAsText(file);
    });
    fileSelector.click();
  };
};

export const exportGame = () => {
  return (dispatch, getState) => {
    validate(dispatch, getState, exportExec, () => {
      alert(i18next.t('EXPORT_ERROR'));
    });
  };
};

const exportExec = (dispatch, getState, tournamentJson, game) => {
  delete tournamentJson.id;
  delete tournamentJson.password;
  delete tournamentJson.allowSignIn;
  for (let i = 0; i < game.targets.length; i++) {
    delete tournamentJson.targets[game.targets[i].id]['results'];
  }

  const fileData = JSON.stringify(tournamentJson);
  const blob = new Blob([fileData], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = 'FriendlyBet_export_template_' + game.name + '.json';
  link.href = url;
  link.click();
  dispatch({ type: LOADING, payload: false });
};

export const toggleShowAllTargets = (showAll) => {
  return {
    type: SHOW_ALL_TARGETS,
    payload: showAll,
  };
};

export const changeTargetOrder = (fromIndex, toIndex) => {
  return (dispatch, getState) => {
    const { game } = getState().tournament;
    const element = game.targets[fromIndex];
    game.targets.splice(fromIndex, 1);
    game.targets.splice(toIndex, 0, element);
    dispatch({ type: GAME_CHANGED, payload: game });
  };
};

export const copyToNewTarget = (target) => {
  return (dispatch) => {
    const newTarget = JSON.parse(JSON.stringify(target));
    delete newTarget.id;
    newTarget.open = true;
    newTarget.name = i18next.t('COPY_PREFIX') + newTarget.name;
    dispatch({ type: COPIED_NEW_TARGET, payload: newTarget });
  };
};

export const importPreTemplate = () => {
  return (dispatch, getState) => {
    const exportedGame = {
      ...TEMPLATE,
      id: '',
      password: '',
      allowSignIn: true,
      targets: addOpenInfoForTargets(Object.values(TEMPLATE.targets), 'new'),
    };
    validate(
      dispatch,
      getState,
      () => {
        dispatch({ type: LOADING, payload: false });
        dispatch({ type: COPIED_GAME, payload: exportedGame });
      },
      () => {
        dispatch({ type: LOADING, payload: false });
      },
      JSON.parse(JSON.stringify(exportedGame)),
    );
  };
};
