import React from 'react';
import { useTranslation } from 'react-i18next';
import Input from './common/Input';
import Title from './common/Title';
import classes from './ScoreLimits.module.css';

const ScoreLimits = ({
  fromValue,
  onFromChange,
  toValue,
  onToChange,
  disabled = false,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className={classes.title}>{t('TARGET_SCORE_LIMIT_TITLE')}</h1>
      <div className={classes.container}>
        <Title title={t('TARGET_SCORE_LIMIT_FROM')} />
        <Input
          integer
          ScoreLimits
          value={fromValue}
          onChange={onFromChange}
          disabled={disabled}
        />
        <Title title={t('TARGET_SCORE_LIMIT_TO')} />
        <Input
          integer
          ScoreLimits
          value={toValue}
          onChange={onToChange}
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default ScoreLimits;
